export function useCounterClickModel() {
  const showModel = ref(false)
  const clicksCounter = ref(0)

  function onClickCounter() {
    clicksCounter.value += 1
    if (clicksCounter.value === 10) {
      showModel.value = true
    }
  }

  watch(
    () => showModel.value,
    (newValue) => {
      if (!newValue) {
        clicksCounter.value = 0
      }
    },
  )

  return {
    showModel,
    onClickCounter,
  }
}
