import isEmpty from 'lodash/isEmpty'
import { useDeepBoxPreviewStore } from '@/stores/deepbox/deepboxes/preview'
import type { PathSegment } from '@/api/types/deepbox/path'
import last from 'lodash/last'
import first from 'lodash/first'
import { constants } from '@/constants'
import type { RouteLocation } from 'vue-router'
import { useNodeSection } from '@/composables/use-node-section.ts'

export interface OriginRoute {
  text: string
  to: Partial<RouteLocation>
}

export function usePreviewView() {
  const deepBoxPreviewStore = useDeepBoxPreviewStore()

  const isNodePreview = computed(
    () =>
      !isEmpty(deepBoxPreviewStore.currentNodeData?.path) &&
      !!deepBoxPreviewStore.currentNodeData?.deepBoxNodeId &&
      !!deepBoxPreviewStore.currentNodeData?.boxNodeId,
  )

  const { getNodeSectionTranslation, getNodeSectionTranslationByRouteName } =
    useNodeSection()

  // eslint-disable-next-line complexity
  const originRoute = computed((): OriginRoute => {
    if (!isNodePreview.value) {
      if (deepBoxPreviewStore.backRoute) {
        return {
          text: getNodeSectionTranslationByRouteName(
            deepBoxPreviewStore.backRoute?.name,
          ),
          to: {
            name: deepBoxPreviewStore.backRoute?.name,
            params: deepBoxPreviewStore.backRoute?.params,
            query: deepBoxPreviewStore.backRoute?.query,
          },
        }
      }
      return { text: '', to: { path: '/' } }
    }

    if (!deepBoxPreviewStore.currentNodeData)
      return { text: '', to: { path: '/' } }

    const lastSegment: PathSegment | undefined = last(
      deepBoxPreviewStore.currentNodeData?.path?.segments,
    )
    const firstSegment: PathSegment | undefined = first(
      deepBoxPreviewStore.currentNodeData?.path?.segments,
    )

    const isParentNodeNestedNode =
      deepBoxPreviewStore.currentNodeData?.path?.segments.length > 1 ||
      undefined

    if (deepBoxPreviewStore.backRoute) {
      let text = getNodeSectionTranslationByRouteName(
        deepBoxPreviewStore.backRoute?.name,
      )
      if (deepBoxPreviewStore.currentNodeData && isParentNodeNestedNode) {
        if (lastSegment?.displayName) {
          text = getNodeSectionTranslation(lastSegment?.displayName)
        }
      }

      return {
        text,
        to: {
          name: deepBoxPreviewStore.backRoute?.name,
          params: deepBoxPreviewStore.backRoute?.params,
          query: deepBoxPreviewStore.backRoute?.query,
        },
      }
    }

    let to: RouteLocation = {
      params: {
        type: deepBoxPreviewStore.currentNodeData?.deepBoxNodeId,
        box: deepBoxPreviewStore.currentNodeData?.boxNodeId,
      },
    }

    // if parent is nested node, then add the `node` param to route

    if (isParentNodeNestedNode) {
      to = {
        ...to,
        params: {
          ...to.params,
          node: lastSegment?.nodeId,
        },
      }
    }

    let text = lastSegment
      ? getNodeSectionTranslation(lastSegment.displayName)
      : ''
    let routeName = ''
    switch (firstSegment?.name) {
      case constants.SECTION_FILES:
        if (isParentNodeNestedNode) {
          routeName = 'organization-types-type-boxes-box-files-node'
        } else {
          text = getNodeSectionTranslation(firstSegment?.name)
          routeName = 'organization-types-type-boxes-box-files'
        }
        break
      case constants.SECTION_TRASH:
        if (isParentNodeNestedNode) {
          routeName = 'organization-types-type-boxes-box-trash-node'
        } else {
          routeName = 'organization-types-type-boxes-box-trash'
        }
        break
      case constants.SECTION_QUEUE:
      case constants.SECTION_INBOX:
        routeName = 'organization-types-type-boxes-box-inbox'
        break

      // no default
    }

    // adding the route name to Route `to`
    to = {
      ...to,
      name: routeName,
    }

    return { text, to }
  })

  return {
    isNodePreview,
    originRoute,
  }
}
