import { useKeycloak } from '@/composables/auth/use-keycloak.ts'
import type { RouteLocationNormalized } from 'vue-router'
import { deepAdminUsersCheckEmailAPI } from '@/api/deepadmin/users/users-check-email.ts'
import type { KeycloakLoginOptions } from 'keycloak-js'
import DeepErrorHandlerService from '@/services/DeepErrorHandlerService.ts'
import { DeepBoxError } from '@/models'
import i18n from '@/plugins/i18n.ts'
import { DeepAppIcon } from '@deepcloud/deep-ui-lib'

export default async function ({
  to,
  params,
}: {
  to: RouteLocationNormalized
  params: Partial<KeycloakLoginOptions>
}) {
  const { isAuthenticated, user, login, register, logout } = useKeycloak()

  let p = { ...params }

  const queryEmail = to.query.email as string
  const queryMailRef = to.query?.['mail-ref'] as string

  if (queryEmail && queryMailRef) {
    p = {
      ...p,
      loginHint: queryEmail,
    }

    if (isAuthenticated.value) {
      if (
        user.value?.email &&
        user.value.email.toLowerCase() !== queryEmail.toLowerCase()
      ) {
        DeepErrorHandlerService.error(
          new DeepBoxError(
            i18n.global.t('deepmail.error.logged_in_wrong_account.message', {
              userEmail: user.value?.email,
              queryEmail,
            }),
            {
              title: i18n.global.t(
                'deepmail.error.logged_in_wrong_account.title',
              ),

              imgComponent: h(DeepAppIcon, { logo: 'deepmail' }),
              btn: {
                text: i18n.global.t('actions.logout'),
                // @ts-expect-error the fn exists on VBtn
                onClick: () => {
                  logout(p)
                },
              },
            },
          ),
        )
        return false
      }
      return
    }

    try {
      const { data } = await deepAdminUsersCheckEmailAPI.post({
        email: queryEmail,
        key: queryMailRef,
      })

      if (data.is_valid) {
        return login(p)
      } else {
        return register({ ...p, prompt: 'force_login_hint' })
      }
    } catch {
      // fallback if API fails
      return login(p)
    }
  }
}
