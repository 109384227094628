import { useDeepAdminUsersMeStore } from '@/stores/deepadmin/users/users-me.ts'
import DeepErrorHandlerService from '@/services/DeepErrorHandlerService.ts'
import { DeepBoxError } from '@/models'
import { useKeycloak } from '@/composables/auth/use-keycloak.ts'
import { deepBoxBoxesInfoAPI } from '@/api/deepbox/boxes/boxes-info.js'
import { useGlobalStore } from '@/stores/global/global.ts'
import type { RouteLocationNormalized } from 'vue-router'
import { useOrganization } from '@/composables/use-organization.ts'

function getLastOrgUsedOrUndefined() {
  const { lastOrgId } = useOrganization()

  const deepAdminUsersMeStore = useDeepAdminUsersMeStore()

  return deepAdminUsersMeStore.getUserSelectableOrganizations.find(
    (org) => org.group_id === lastOrgId.value,
  )
}

async function caseRouteDoNotHasOrganizationIdAndNoLastOrgIfFound() {
  const deepAdminUsersMeStore = useDeepAdminUsersMeStore()
  const globalStore = useGlobalStore()
  const { lastOrgId } = useOrganization()

  const lastUserOrg = getLastOrgUsedOrUndefined()
  if (lastUserOrg) {
    deepAdminUsersMeStore.selectedOrganization = { ...lastUserOrg }
  }

  let orgGroupId = deepAdminUsersMeStore.selectedOrganizationId
  if (!orgGroupId) {
    // set 1. org on the users list if there is not selected Organization
    orgGroupId =
      deepAdminUsersMeStore.getUserSelectableOrganizations[0].group_id
    deepAdminUsersMeStore.selectedOrganization = {
      ...deepAdminUsersMeStore.getUserSelectableOrganizations[0],
    }
  }
  if (orgGroupId) {
    lastOrgId.value = orgGroupId
  }

  globalStore.fetchInitialPending = false
}

async function caseDashboardEmptyParamRoute(to: RouteLocationNormalized) {
  const deepAdminUsersMeStore = useDeepAdminUsersMeStore()

  if (deepAdminUsersMeStore.organizations.length === 0) {
    await deepAdminUsersMeStore.fetchUserOrganizations()
  }

  const lastUserOrg = getLastOrgUsedOrUndefined()
  if (lastUserOrg) {
    deepAdminUsersMeStore.selectedOrganization = { ...lastUserOrg }
  }

  // if the user is authenticated and navigate to the "organisation" path without organisation param
  // be sure that the selected organisation is used and the organisation id is on the url
  const selectedOrganizationGroupId =
    deepAdminUsersMeStore.selectedOrganizationId

  const selectedOrganizationGroupIdExists =
    deepAdminUsersMeStore.getUserSelectableOrganizations.findIndex(
      (org) => org.group_id === selectedOrganizationGroupId,
    )

  const orgId =
    selectedOrganizationGroupIdExists !== -1
      ? selectedOrganizationGroupId
      : deepAdminUsersMeStore.getUserSelectableOrganizations[0].group_id
  if (orgId) {
    return {
      name: 'organization',
      params: {
        organization: orgId,
      },
      hash: to.hash,
    }
  }
}

function caseUserDoNotHaveAnyOrganizations() {
  window.open(import.meta.env.VITE_DEEPADMIN_FRONTEND_BASE_URL, '_self')
  return
}

async function caseRouteHasOrganizationId(to: RouteLocationNormalized) {
  const deepAdminUsersMeStore = useDeepAdminUsersMeStore()
  const globalStore = useGlobalStore()

  const { lastOrgId } = useOrganization()

  const toParamOrganization = to.params?.organization

  // Find org with path param
  const org = deepAdminUsersMeStore.getUserSelectableOrganizations.find(
    (o) => o.group_id === toParamOrganization,
  )

  // If org is part of user organizations, and different as already selected, switch
  if (org) {
    if (toParamOrganization) {
      lastOrgId.value = toParamOrganization
    }
    if (org.group_id !== deepAdminUsersMeStore.selectedOrganizationId) {
      deepAdminUsersMeStore.selectedOrganization = { ...org }
    }
    globalStore.fetchInitialPending = false

    return
  } else {
    // If path param is different as selected org, redirect
    if (to.params?.box) {
      try {
        const res = await deepBoxBoxesInfoAPI.get(to.params?.box)
        if (res.data?.accessGrantedBy.length > 0) {
          if (to.name) {
            return {
              name: to.name,
              params: {
                ...to.params,
              },
              hash: to.hash,
            }
          }
          return
        }
        return
      } catch (e: unknown) {
        if (e && e.response) {
          const errorCode = e.response.status
          if ([403, 404].includes(errorCode)) {
            DeepErrorHandlerService.error(
              new DeepBoxError('error.403.box_not_found_or_no_access', {
                statusCode: errorCode,
              }),
            )
            return
          } else {
            DeepErrorHandlerService.error(
              new DeepBoxError('error.error_occurred', {
                statusCode: errorCode,
              }),
            )
            return
          }
        }
      } finally {
        globalStore.fetchInitialPending = false
      }
    }

    console.warn(`Organization with id: ${toParamOrganization}: not found`)
    DeepErrorHandlerService.error(
      new DeepBoxError('error.organization_not_found', {
        statusCode: 400,
      }),
    )
    globalStore.fetchInitialPending = false
    return
  }
}

export default async function ({ to }: { to: RouteLocationNormalized }) {
  const { isAuthenticated } = useKeycloak()
  const deepAdminUsersMeStore = useDeepAdminUsersMeStore()
  const globalStore = useGlobalStore()

  const toParamOrganization = to.params?.organization

  if (
    !isAuthenticated.value ||
    (deepAdminUsersMeStore.selectedOrganizationId &&
      deepAdminUsersMeStore.selectedOrganizationId === toParamOrganization)
  ) {
    console.log('Middleware (needs-organization-context): Case 01')
    globalStore.fetchInitialPending = false
    return
  }

  const { lastOrgId } = useOrganization()

  globalStore.fetchInitialPending = true

  const isDashboardEmptyParamRoute =
    to.name === 'organization' && !toParamOrganization

  if (isDashboardEmptyParamRoute) {
    console.log('Middleware (needs-organization-context): Case 02')
    return caseDashboardEmptyParamRoute(to)
  }

  if (deepAdminUsersMeStore.organizations.length === 0) {
    await deepAdminUsersMeStore.fetchUserOrganizations()
  }

  if (deepAdminUsersMeStore.getUserSelectableOrganizations.length === 0) {
    console.log('Middleware (needs-organization-context): Case 03')
    return caseUserDoNotHaveAnyOrganizations()
  }

  // Get last org used
  const lastUserOrg = getLastOrgUsedOrUndefined()

  // Check if last org is part of user's organizations, if not remove key and set lastOrgId to null
  if (!lastUserOrg) {
    lastOrgId.value = undefined
  }

  if (toParamOrganization) {
    console.log('Middleware (needs-organization-context): Case 04')
    return caseRouteHasOrganizationId(to)
  } else if (!lastOrgId.value) {
    console.log('Middleware (needs-organization-context): Case 05')
    return caseRouteDoNotHasOrganizationIdAndNoLastOrgIfFound()
  } else {
    console.log('Middleware (needs-organization-context): Case 06')
    if (lastUserOrg) {
      deepAdminUsersMeStore.selectedOrganization = { ...lastUserOrg }
    }
    globalStore.fetchInitialPending = false
    if (to.name === 'organization') {
      return {
        name: 'organization',
        params: {
          organization: lastOrgId.value,
        },
        hash: to.hash,
        replace: true,
      }
    }
  }
}
