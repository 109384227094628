<template>
  <v-layout>
    <!-- APP BAR -->
    <TheAppBar :hide-logo="smAndDown" :is-mobile="device?.isMobile">
      <template #desktop-prepend-end>
        <div
          v-if="
            (deepBoxBoxInfoStore.accessCompany &&
              !isBreakpointSmAndDownOrTabletDevice) ||
            isBoxPrivate
          "
          class="text-body-2 text-truncate font-weight-medium"
        >
          <!-- Show user display name if boxType `private`  -->
          <span v-if="isBoxPrivate && user && user.display_name">
            {{ user.display_name }}
          </span>
          <span v-else>
            {{ deepBoxBoxInfoStore.accessCompany?.displayName }}
          </span>
        </div>
        <BoxSelectionMenu v-else />
      </template>

      <template
        v-if="!isBreakpointSmAndDownOrTabletDevice && !isBoxPrivate"
        #desktop-center
      >
        <BoxSelectionMenu />
      </template>

      <template v-if="!isBoxPrivate" #desktop-append>
        <AppBarFavoritesMenu
          v-if="deepBoxBoxInfoStore.accessCompany?.companyId && overview"
          :favorite-boxes="overview.favorites.boxes"
          :organization-id="deepBoxBoxInfoStore.accessCompany.companyId"
          :shared-boxes="overview.sharedWithMe.boxes"
        />
        <AppBarRecentMenu
          v-if="deepBoxBoxInfoStore.accessCompany?.companyId && overview"
          :organization-id="deepBoxBoxInfoStore.accessCompany.companyId"
          :recent-boxes="overview.recents.boxes"
          :shared-boxes="overview.sharedWithMe.boxes"
        />
        <AppBarServiceMenu
          v-if="deepAdminUsersMeStore.selectedOrganizationId"
          :box="deepBoxCoreStore.selectedBox"
          :current-organization="deepAdminUsersMeStore.selectedOrganization"
        />
        <DeepFlowTasksMenu
          v-if="settingsDevStore.canModeFeature('DEEP_FLOW')"
          :box-id="params.box"
          :organization-id="deepBoxBoxInfoStore.accessCompany?.companyId"
          :type-id="params.type"
        />
      </template>

      <!-- MOBILE -->
      <template v-if="globalStore.mobileSearch" #mobile-prepend>
        <v-btn
          class="mx-2"
          density="comfortable"
          icon="fas fa-arrow-left"
          @click="globalStore.mobileSearch = !globalStore.mobileSearch"
        >
        </v-btn>
      </template>
      <template #mobile-content>
        <div
          v-if="!globalStore.mobileSearch && !isBoxPrivate"
          class="d-flex ga-2"
        >
          <BoxSelectionMenu />
        </div>
        <SearchBarMobile v-else-if="globalStore.mobileSearch" />
        <div
          v-else-if="deepBoxBoxInfoStore.accessCompany || isBoxPrivate"
          class="text-body-2 text-truncate font-weight-medium"
        >
          <!-- Show user display name if boxType `private`  -->
          <span v-if="isBoxPrivate && user && user.display_name">
            {{ user.display_name }}
          </span>
          <span v-else>
            {{ deepBoxBoxInfoStore.accessCompany?.displayName }}
          </span>
        </div>
      </template>
      <template #mobile-append>
        <v-btn
          class="mx-2"
          density="comfortable"
          icon="far fa-magnifying-glass"
          @click="globalStore.mobileSearch = !globalStore.mobileSearch"
        >
        </v-btn>
      </template>
    </TheAppBar>
    <!-- /MOBILE -->
    <!-- /APP BAR -->

    <!-- NAVIGATION MENU -->
    <BoxNavigationDrawer
      v-model="drawer"
      :box-id="deepBoxCoreStore.selectedBox?.boxNodeId"
      :type-id="deepBoxCoreStore.selectedBox?.deepBoxNodeId"
    />
    <!-- /NAVIGATION MENU -->

    <!-- DETAILS DRAWER -->
    <BoxDetailDrawer
      :box-id="params.box"
      :nodes="deepBoxDeepBoxesBoxesNodesStore.selectedNodes"
      :organization-id="deepBoxBoxInfoStore.accessCompany?.companyId"
      :type-id="params.type"
    />
    <!-- /DETAILS DRAWER -->

    <v-main fullscreen>
      <v-container class="pa-0 ma-0 prevent-select" fluid>
        <router-view />
        <NodeUploadCard />
      </v-container>
    </v-main>

    <ServiceDownload />
  </v-layout>
</template>

<script lang="ts" setup>
import BoxDetailDrawer from '@/components/box/BoxDetailDrawer.vue'
import ServiceDownload from '@/components/services/ServiceDownload.vue'
import AppBarFavoritesMenu from '@/components/appbar/AppBarFavoritesMenu.vue'
import AppBarRecentMenu from '@/components/appbar/AppBarRecentMenu.vue'
import BoxNavigationDrawer from '@/components/box/BoxNavigationDrawer.vue'
import TheAppBar from '@/components/appbar/TheAppBar.vue'
import BoxSelectionMenu from '@/components/box/BoxSelectionMenu.vue'
import DeepFlowTasksMenu from '@/components/deepflow/DeepFlowTasksMenu.vue'
import AppBarServiceMenu from '@/components/appbar/AppBarServiceMenu.vue'
import NodeUploadCard from '@/components/node/NodeUploadCard.vue'
import SearchBarMobile from '@/components/search/SearchBarMobile.vue'
import { useDeepAdminUsersMeStore } from '@/stores/deepadmin/users/users-me'
import { useGlobalStore } from '@/stores/global/global'
import { useDeepBoxCoreStore } from '@/stores/deepbox/core'
import { useSettingsDevStore } from '@/stores/settings/settings-dev'
import { useDisplay } from 'vuetify'
import { DeviceKey } from '@/plugins/device-detector-js.ts'
import { useDeepBoxDeepBoxesBoxesNodesStore } from '@/stores/deepbox/deepboxes/boxes/nodes'
import { useBox } from '@/composables/use-box.ts'
import { useKeycloak } from '@/composables/auth/use-keycloak.ts'
import { useParams } from '@deepcloud/deep-ui-lib'
import { useDeepBoxBoxInfoStore } from '@/stores/deepbox/box-info.ts'
import type {
  Overview,
  OverviewQueryParams,
} from '@/api/types/deepbox/overview.ts'
import { deepBoxOverviewAPI } from '@/api/deepbox/overview/overview.ts'
import { toast } from 'vue-sonner'
import { createBoxBreadcrumbsContext } from '@/composables/use-box-breadcrumbs.ts'

const device = inject(DeviceKey)
const { smAndDown, lgAndUp } = useDisplay()
const { t } = useI18n()
const { user } = useKeycloak()

const drawer = ref(lgAndUp.value)
provide('boxDrawer', drawer)

const deepAdminUsersMeStore = useDeepAdminUsersMeStore()
const deepBoxCoreStore = useDeepBoxCoreStore()
const deepBoxDeepBoxesBoxesNodesStore = useDeepBoxDeepBoxesBoxesNodesStore()
const globalStore = useGlobalStore()
const settingsDevStore = useSettingsDevStore()
const deepBoxBoxInfoStore = useDeepBoxBoxInfoStore()

const params = useParams<{
  type: string
  box: string
}>()

const { isBoxPrivate } = useBox(computed(() => deepBoxCoreStore.selectedBox))

const { selectedBox } = storeToRefs(deepBoxCoreStore)

const segments = shallowRef(
  deepBoxDeepBoxesBoxesNodesStore.data?.path?.segments ?? [],
)

createBoxBreadcrumbsContext(segments, selectedBox)

watch(
  () => deepBoxDeepBoxesBoxesNodesStore.data,
  (newValue) => {
    if (newValue) {
      segments.value = newValue.path.segments
    }
  },
  { immediate: true },
)

const isBreakpointSmAndDown = computed(() => smAndDown.value)

const isBreakpointSmAndDownOrTabletDevice = computed(
  () => device?.isTablet || isBreakpointSmAndDown.value,
)

const overview = ref<Overview>()
provide('boxOverview', overview)

async function onFetchOverview(companyId: string) {
  try {
    const params: OverviewQueryParams = {
      'deepBoxes.boxes.limit': 10,
      companyId,
    }
    const res = await deepBoxOverviewAPI.get(params)

    overview.value = res.data
  } catch {
    toast.error(t('error.error_occurred'))
  }
}

watch(
  () => deepBoxBoxInfoStore.accessCompany?.companyId,
  (newValue) => {
    if (newValue) {
      onFetchOverview(newValue)
    }
  },
  { immediate: true },
)
</script>
<style lang="scss" scoped>
.mobile_box_name {
  height: 20px;
  font-size: 13px;
  font-weight: bolder;
  margin: 4px 0 0;
  padding: 0;
}

.mobile_organisation_name {
  height: 20px;
  font-size: 10px;
  margin: -4px 0 0;
  padding: 0;
  color: rgb(var(--v-theme-grey));
}
</style>
