<template>
  <!-- MOBILE APPBAR -->
  <DeepHeader
    v-if="isMobile"
    :env="currentEnv"
    :hide-app-switch="computedHideAppSwitch"
    :logo="logo"
  >
    <template #prepend>
      <slot name="mobile-prepend"></slot>
    </template>

    <template #prepend-end>
      <slot name="mobile-content"></slot>
    </template>

    <template #append>
      <slot name="mobile-append"></slot>
      <div v-if="globalStore.mobileSearch"></div>
      <template v-else>
        <AppBarUserAccountMenu />
      </template>
    </template>
  </DeepHeader>
  <!-- /MOBILE APPBAR -->
  <!-- DESKTOP APPBAR -->
  <DeepHeader
    v-else
    :env="currentEnv"
    :hide-app-switch="computedHideAppSwitch"
    :logo="logo"
    @click:logo="onClickCounter"
  >
    <template #prepend-start>
      <slot name="desktop-prepend-start"></slot>
    </template>
    <template #prepend-end>
      <slot name="desktop-prepend-end"></slot>
    </template>

    <template v-if="$slots['desktop-center']" #center>
      <v-spacer />
      <slot class="v-toolbar__center" name="desktop-center"></slot>
      <v-spacer />
    </template>
    <template #append-start>
      <slot name="desktop-append"></slot>
    </template>
    <template #append>
      <div v-if="globalStore.mobileSearch"></div>
      <template v-else>
        <AppBarUserAccountMenu />
      </template>
    </template>
  </DeepHeader>
  <!-- /DESKTOP APPBAR -->

  <DialogAppInfo v-model="showModel" />
</template>

<script lang="ts" setup>
import { DeepHeader } from '@deepcloud/deep-ui-lib'
import AppBarUserAccountMenu from '@/components/appbar/AppBarUserAccountMenu.vue'
import DialogAppInfo from '@/components/dialogs/DialogAppInfo.vue'
import { useCounterClickModel } from '@/composables/use-counter-click-model.ts'
import { useDeepAdminUsersMeStore } from '@/stores/deepadmin/users/users-me.ts'
import { useGlobalStore } from '@/stores/global/global.ts'
import { useOrganization } from '@/composables/use-organization.ts'

const globalStore = useGlobalStore()

const props = defineProps({
  isMobile: {
    type: Boolean,
    default: false,
  },
  hideLogo: {
    type: Boolean,
    default: false,
  },
  hideAppSwitch: {
    type: Boolean,
    default: false,
  },
})
const { showModel, onClickCounter } = useCounterClickModel()
const deepAdminUsersMeStore = useDeepAdminUsersMeStore()

const currentEnv = import.meta.env.VITE_ENVIRONMENT

const { lastOrgId } = useOrganization()

const orgId = computed(() => {
  if (deepAdminUsersMeStore.selectedOrganizationId)
    return deepAdminUsersMeStore.selectedOrganizationId

  if (lastOrgId.value) return lastOrgId.value

  if (deepAdminUsersMeStore.getUserSelectableOrganizations.length > 0) {
    return deepAdminUsersMeStore.getUserSelectableOrganizations[0]?.group_id
  }

  return undefined
})

const logo = computed(() => {
  if (props.hideLogo) {
    return undefined
  }

  return {
    appId: 'deepbox',
    to: {
      name: 'organization',
      params: {
        organization: orgId.value,
      },
    },
  }
})

const computedHideAppSwitch = computed(
  () => props.hideAppSwitch || globalStore.mobileSearch,
)
</script>
