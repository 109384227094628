<template>
  <CoreNavigationDrawerListItem
    v-for="generic in generics"
    :key="generic.id"
    :data-test-id="`box-navigation-generic-${generic.name}`"
    exact
    nav
    :title="generic.displayName"
    :to="{
      name: 'organization-types-type-boxes-box-files-node',
      params: {
        node: generic.nodeId,
      },
    }"
  >
    <template #icon>
      <CoreTooltip :location="isDrawerMini ? 'right' : 'top'">
        <template #activator="{ props: ActivatorProps }">
          <v-avatar v-bind="ActivatorProps" class="pr-1 mr-1" size="24" tile>
            <DeepAppIcon
              v-if="generic.id === 'deepmail'"
              height="100%"
              logo="deepmail"
              width="100%"
            />
            <NodeFolderAvatar v-else size="20" />
          </v-avatar>
        </template>
        <span class="text-caption">
          <span v-if="generic.id === 'deepmail'"> DeepMail </span>
          <span v-else>
            {{ t('navigation.dashboard') }}
          </span>
        </span>
      </CoreTooltip>
    </template>
  </CoreNavigationDrawerListItem>
</template>

<script lang="ts" setup>
// components
import CoreNavigationDrawerListItem from '@/components/core/CoreNavigationDrawerListItem.vue'
import CoreTooltip from '@/components/core/CoreTooltip.vue'
import { DeepAppIcon } from '@deepcloud/deep-ui-lib'
// composables
import { toast } from 'vue-sonner'
// apis
import { deepBoxDeepBoxesBoxesGenericsAPI } from '@/api/deepbox/deepboxes/deepboxes-boxes-generics.ts'
// types & constants
import type { GenericFolder } from '@/api/types/deepbox/generics.ts'
import NodeFolderAvatar from '@/components/node/NodeFolderAvatar.vue'

const props = defineProps({
  typeId: {
    type: String,
    required: true,
  },
  boxId: {
    type: String,
    required: true,
  },
  isDrawerMini: {
    type: Boolean,
    default: false,
  },
})

const { t } = useI18n()

const generics = ref<GenericFolder[]>([])

async function getBoxGenerics() {
  if (!props.typeId || !props.boxId) return
  try {
    const { data } = await deepBoxDeepBoxesBoxesGenericsAPI.get(
      props.typeId,
      props.boxId,
    )

    generics.value = data
  } catch {
    toast.error(t('error.error_occurred'))
  }
}

getBoxGenerics()
</script>
