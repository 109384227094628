import {
  createRouter,
  createWebHistory,
  type RouteLocationNormalized,
} from 'vue-router'
import { captureException } from '@sentry/browser'
// ROUTES
import invitationRoutes from './routes/invitation'
import linkRoutes from './routes/link'
import watchesRoutes from './routes/watches'
import uploadRoutes from './routes/upload'
// MIDDLEWARES
import routeNotFound from './middleware/route-not-found'
import needsOrganizationContext from './middleware/needs-organization-context'
import middlewareDeepMail from './middleware/deep-mail'
// LAYOUTS
import LayoutBoxDetail from '@/layouts/LayoutBoxDetail.vue'
import LayoutDefault from '@/layouts/LayoutDefault.vue'
import LayoutFilePreview from '@/layouts/LayoutFilePreview.vue'
import LayoutEmpty from '@/layouts/LayoutEmpty.vue'
import LayoutStore from '@/layouts/LayoutStore.vue'
import LayoutError from '@/layouts/LayoutError.vue'
// SERVICES
import { useKeycloak } from '@/composables/auth/use-keycloak'
import { isLocalOrDevEnv } from '@/utils/helpers/env.ts'
import { deepOPortalMappingsAPIService } from '@/api/deepo/portal-mappings.ts'
import type { KeycloakLoginOptions } from 'keycloak-js'
import { useDeepBoxBoxInfoStore } from '@/stores/deepbox/box-info.ts'
import { useDeepBoxCoreStore } from '@/stores/deepbox/core.ts'
import DeepErrorHandlerService from '@/services/DeepErrorHandlerService'
import { useDeepAdminUsersMeStore } from '@/stores/deepadmin/users/users-me.ts'

const routes = [
  {
    path: '/:catchAll(.*)*',
    alias: '/error-page',
    name: 'error-page',
    component: LayoutError,
    meta: {
      auth: false,
    },
    props: {
      error: {
        statusCode: 404,
        title: 'error.page_not_found',
        deepBoxError: true,
      },
    },
    beforeEnter: (to: RouteLocationNormalized) =>
      routeNotFound({
        to,
      }),
  },
  {
    path: '/',
    component: LayoutDefault,
    redirect: { name: 'organization' },
    meta: {
      needsOrganizationContext: true,
    },
    children: [
      {
        path: '/client-apps/',
        component: () => import('@/pages/ClientApps.vue'),
        name: 'client-apps',
      },
    ],
  },
  {
    path: '/:organization?',
    component: () => import('@/pages/organization/OrganizationDashboard.vue'),
    name: 'organization',
    meta: {
      needsOrganizationContext: true,
    },
  },
  // STORE
  {
    path: '/:organization/store/',
    component: LayoutStore,
    name: 'organization-store',
    redirect: '/:organization/store/services/:service',
    children: [
      {
        path: '/:organization/store/services/:service',
        component: () =>
          import('@/pages/organization/store/service/StoreServiceIndex.vue'),
        name: 'organization-store-services-service',
        props: true,
      },
      {
        path: '/:organization/store/services/:service?/process',
        component: () =>
          import('@/pages/organization/store/service/StoreServiceProcess.vue'),
        name: 'organization-store-services-service-process',
      },
    ],
  },
  // ORGANIZATION FILE PREVIEW
  {
    path: '/node/:node/preview',
    component: LayoutFilePreview,
    meta: {
      needsOrganizationContext: true,
    },
    children: [
      {
        path: '',
        name: 'node-preview',
        component: () => import('@/pages/node/NodePreview.vue'),
      },
    ],
  },
  // OO NODE EDITOR
  {
    path: '/node/:node/edit',
    component: LayoutEmpty,
    children: [
      {
        path: '',
        name: 'node-edit',
        component: () => import('@/pages/node/NodeEdit.vue'),
      },
    ],
  },

  // BOX DETAIL
  {
    path: '/types/:type/boxes/:box',
    component: LayoutBoxDetail,
    meta: {
      needsOrganizationContext: true,
    },
    beforeEnter: async (to: RouteLocationNormalized) => {
      const { type, box } = to.params
      const deepBoxBoxInfoStore = useDeepBoxBoxInfoStore()

      await deepBoxBoxInfoStore.fetchBoxInfo(box as string)

      if (deepBoxBoxInfoStore.boxInfo) {
        const deepBoxCoreStore = useDeepBoxCoreStore()
        deepBoxCoreStore.selectedBox = { ...deepBoxBoxInfoStore.boxInfo }
      }

      // const organization = deepBoxBoxInfoStore.accessCompany?.companyId
      const organization = deepBoxBoxInfoStore.boxInfo?.company?.companyId

      if (isLocalOrDevEnv() && organization) {
        try {
          const { data } = await deepOPortalMappingsAPIService.get(
            organization,
            {
              deepbox_node_id: type as string,
              box_node_id: box as string,
            },
          )
          if (data && data.portalUrl) {
            window.open(data.portalUrl, '_self')
            return
          }
        } catch {
          return // Navigate to a fallback route
        }
      }
      // Continue navigation for non-local environments
    },
    children: [
      {
        path: '',
        redirect: { name: 'organization-types-type-boxes-box' }, // Redirect to BoxIndex
      },
      {
        path: '',
        name: 'organization-types-type-boxes-box',
        component: () => import('@/pages/organization/type/box/BoxIndex.vue'),
      },
      {
        path: '',
        component: () => import('@/pages/organization/type/box/BoxLayout.vue'),
        children: [
          {
            path: 'files',
            component: () =>
              import('@/pages/organization/type/box/BoxFolderView.vue'),
            name: 'organization-types-type-boxes-box-files',
          },
          {
            path: 'files/:node',
            component: () =>
              import('@/pages/organization/type/box/BoxFolderView.vue'),
            name: 'organization-types-type-boxes-box-files-node',
          },
          {
            path: 'inbox',
            component: () =>
              import('@/pages/organization/type/box/BoxFolderView.vue'),
            name: 'organization-types-type-boxes-box-inbox',
          },
          {
            path: 'search',
            component: () =>
              import('@/pages/organization/type/box/BoxSearch.vue'),
            name: 'organization-types-type-boxes-box-search',
            props: true,
          },
          {
            path: 'trash',
            component: () =>
              import('@/pages/organization/type/box/BoxFolderView.vue'),
            name: 'organization-types-type-boxes-box-trash',
          },
          {
            path: 'trash/:node',
            component: () =>
              import('@/pages/organization/type/box/BoxFolderView.vue'),
            name: 'organization-types-type-boxes-box-trash-node',
          },
        ],
      },
    ],
  },
  // INVITATION
  ...invitationRoutes,
  // LINK
  ...linkRoutes,
  // UPLOAD
  // WATCHES
  ...watchesRoutes,
  // UPLOAD
  ...uploadRoutes,
]

// Create fn to init the router in order to solve an issue where KC query params stay on the URL
// https://github.com/dsb-norge/vue-keycloak-js/issues/94#issuecomment-1794403391
// https://github.com/keycloak/keycloak/issues/14742#issuecomment-1663069438
const initializeRouter = () => {
  const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes,
  })

  router.beforeEach(async (to) => {
    // ############ CLEAR ERROR IF EXISTS #########
    DeepErrorHandlerService.clearErrorIfExists()

    const routeLangQuery = (to.query?.lang as string) || ''

    const basePath = window.location.origin
    const keycloakRedirectUri = basePath + to.fullPath
    const toRouteNeedsAuth = to.meta.auth !== false
    const { isAuthenticated, login } = useKeycloak()

    const params: KeycloakLoginOptions = {
      redirectUri: keycloakRedirectUri,
    }
    if (routeLangQuery) {
      params.locale = routeLangQuery
    }
    const queryEmail = to.query.email as string
    const queryMailRef = to.query?.['mail-ref'] as string

    if (isAuthenticated.value) {
      const deepAdminUsersMeStore = useDeepAdminUsersMeStore()
      if (!deepAdminUsersMeStore.user) {
        await deepAdminUsersMeStore.fetchUserOrganizations()
      }
    }
    if (toRouteNeedsAuth) {
      if (queryEmail && queryMailRef) {
        return middlewareDeepMail({ to, params })
      }
      if (!isAuthenticated.value) {
        console.log('Route need auth but user is not authenticated')

        return login(params)
      } else {
        const paramNeedsOrganizationContext = to.matched.some(
          (m) => m.meta.needsOrganizationContext,
        )
        if (paramNeedsOrganizationContext) {
          return needsOrganizationContext({ to })
        }
      }
    }
  })

  // Hard-reload the page when chunk load errors match the navigation error
  // https://github.com/vitejs/vite/issues/11804#issuecomment-1760951463
  router.onError((error, to) => {
    const errors = [
      'Failed to fetch dynamically imported module',
      'Unable to preload CSS',
      'error loading dynamically imported module',
    ]

    if (errors.some((e) => error?.message?.includes(e))) {
      if (to?.fullPath) {
        window.location.href = to.fullPath
      } else {
        window.location.reload()
      }
    } else {
      captureException('Triggered automatic reload after router error. ', {
        extra: error.message,
      })
    }
  })

  console.log('ROUTER: router initialized')

  return router
}

export default initializeRouter
