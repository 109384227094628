<template>
  <v-menu
    v-if="organizations.length > 0"
    :close-on-content-click="true"
    :disabled="isMenuDisabled"
    location="bottom"
  >
    <!-- MENU BUTTON -->
    <template #activator="{ props: ActivatorProps, isActive }">
      <v-btn
        v-bind="ActivatorProps"
        :append-icon="
          hideMenuIcon
            ? undefined
            : isActive
              ? 'far fa-angle-up'
              : 'far fa-angle-down'
        "
        class="selected-organization-btn text-h6 pl-0"
        :ripple="false"
        :style="buttonStyle"
        variant="text"
      >
        <!-- DEFAULT BUTTON -->
        <v-list-item class="px-0">
          <div class="organization-name text-truncate">
            {{ selectedOrganizationDisplayName }}
          </div>
        </v-list-item>
        <!-- DEFAULT BUTTON -->
      </v-btn>
    </template>
    <!-- /MENU BUTTON -->

    <!-- MENU CARD -->
    <v-card class="pa-2" scrollable>
      <v-card-text
        class="pa-0 ma-0"
        :style="{
          maxHeight: xs ? '430px' : '530px',
          overflowY: 'scroll',
        }"
      >
        <!-- ORGANIZATIONS LIST -->
        <v-list class="py-1" density="compact">
          <v-list-item
            v-for="organization in organizations"
            :key="organization.group_id"
            :style="selectedStyle(organization)"
            :subtitle="getCity(organization)"
            :title="organization.display_name"
            @click="changeOrganization(organization)"
          >
            <template #prepend>
              <DeepAvatar
                class="text-body-2"
                :name="organization.display_name"
                rounded="circle"
              />
            </template>
            <template
              v-if="organization.group_id === selectedOrganization.group_id"
              #append
            >
              <v-icon color="success">far fa-check</v-icon>
            </template>
          </v-list-item>
        </v-list>
        <!-- /ORGANIZATIONS LIST -->
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <!-- CREATE NEW ORGANIZATION BUTTON -->
        <VBtnTertiary
          block
          :href="ONBOARDING_LINKS.ONBOARDING_LINK_ACCOUNT"
          size="large"
          target="_self"
        >
          {{ t('menu.create_new_deepbox') }}
        </VBtnTertiary>
        <!-- /CREATE NEW ORGANIZATION BUTTON -->
        <v-spacer />
      </v-card-actions>
    </v-card>
    <!-- /MENU CARD -->
  </v-menu>
</template>
<script lang="ts" setup>
import { DeepAvatar } from '@deepcloud/deep-ui-lib'
import helpers from '@/utils/helpers.ts'
import { ONBOARDING_LINKS } from '@/constants/onboarding'
import type { UserMeOrganization } from '@/api/types/deepadmin/users/user.ts'
import { useDisplay } from 'vuetify'
import { useOrganization } from '@/composables/use-organization.ts'

const props = defineProps({
  organizations: {
    type: Array as PropType<UserMeOrganization[]>,
    required: true,
  },
  selectedOrganization: {
    type: Object as PropType<UserMeOrganization>,
    required: true,
  },
  isMenuDisabled: {
    type: Boolean,
    default: false,
  },
  hideMenuIcon: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['change:organization'])

const { t } = useI18n()
const { xs } = useDisplay()

const buttonStyle = computed(() =>
  props.organizations?.length === 1
    ? 'cursor: default; box-shadow: none;'
    : 'box-shadow: none',
)

const currentOrganization = computed(() =>
  helpers.currentOrganization(props.selectedOrganization, props.organizations),
)

const selectedOrganizationDisplayName = computed(() => {
  if (currentOrganization.value) {
    return currentOrganization.value.display_name
  }
  return props.selectedOrganization.display_name
})

function getCity(org: UserMeOrganization) {
  return org?.default_address?.city || ''
}

const { lastOrgId } = useOrganization()

function changeOrganization(org: UserMeOrganization) {
  if (org.group_id && props.selectedOrganization.group_id !== org.group_id) {
    lastOrgId.value = org.group_id
  }
  emit('change:organization', org)
}

function selectedStyle(org: UserMeOrganization) {
  if (props.selectedOrganization.group_id === org.group_id) {
    return 'background-color: #f3f0ff; border-radius: 6px; height: 100%'
  }
  return null
}
</script>
<style lang="scss" scoped>
.selected-organization-btn {
  :deep(.v-btn__overlay) {
    background-color: transparent;
  }
}

.organization-name {
  letter-spacing: 0;
}
</style>
