<template>
  <div class="px-2">
    <div v-if="content" v-html="getSanitizedHtml(content)" />
    <v-data-table
      class="my-2"
      density="compact"
      fixed-header
      :headers="headers"
      hide-default-footer
      :items="items"
      style="max-height: 150px"
    />
  </div>
</template>

<script lang="ts" setup>
import { LogEntry } from '@/api/types/deepbox/log-entry.ts'
import { getSanitizedHtml } from '@deepcloud/deep-ui-lib'

const props = defineProps({
  content: {
    type: String,
    default: undefined,
  },
  items: {
    type: Array as PropType<LogEntry[]>,
    default: () => [],
  },
})

const { t } = useI18n()

const headers = computed(() => {
  const headers = [
    {
      title: t('node.virus.table.headers.file'),
      value: 'context',
      sortable: false,
    },
    {
      title: t('node.virus.table.headers.virus_signature'),
      value: 'info',
      sortable: false,
    },
  ]
  if (props.items.some((item) => item.info !== null)) {
    return headers
  }
  return headers.filter((header) => header.value !== 'info')
})
defineExpose({ open })
</script>
