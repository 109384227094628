<template>
  <CoreMenuItems
    v-model="model"
    v-bind="$attrs"
    density="compact"
    :items="filteredItems"
    @click:item="onClickItem"
  >
    <template v-if="!isContextMenu" #activator="{ props: ActivatorProps }">
      <v-btn
        v-bind="ActivatorProps"
        icon="far fa-ellipsis-vertical"
        name="btn-node-action-menu"
        size="x-small"
        slim
        variant="plain"
      >
      </v-btn>
    </template>
  </CoreMenuItems>

  <BoxAdminCreateDialog
    v-model="dialogCreateBox"
    :box-id="box.boxNodeId"
    :type-id="box.deepBoxNodeId"
    @box:updated="emit('box:updated', $event)"
  />
</template>

<script setup lang="ts">
// components
import CoreMenuItems from '@/components/core/CoreMenuItems.vue'
import BoxAdminCreateDialog from '@/components/box/box-admin/BoxAdminCreateDialog.vue'

// composables
import { useMsgBox } from '@deepcloud/deep-ui-lib'
import { toast } from 'vue-sonner'

// stores
import { useDeepBoxDeepBoxesBoxesFavoritesStore } from '@/stores/deepbox/deepboxes/boxes/favorites.ts'

// apis
import { deepBoxAdminDeepBoxesBoxesAPI } from '@/api/deepbox/admin/admin-deepboxes-boxes.ts'

// types & constants
import type { BoxEntry } from '@/api/types/deepbox/box.ts'

const props = defineProps({
  box: {
    type: Object as PropType<BoxEntry>,
    required: true,
  },
  isContextMenu: {
    type: Boolean,
    default: false,
  },
})

const model = defineModel({ type: Boolean, default: false })
const emit = defineEmits(['box:updated', 'box:added', 'box:deleted'])

const { t } = useI18n()
const deepMsgBox = useMsgBox()

const dialogCreateBox = ref(false)

const deepBoxDeepBoxesBoxesFavoritesStore =
  useDeepBoxDeepBoxesBoxesFavoritesStore()

function isFav(box: BoxEntry) {
  if (deepBoxDeepBoxesBoxesFavoritesStore.favorites.length > 0) {
    return deepBoxDeepBoxesBoxesFavoritesStore.favorites.find(
      (fav) => fav.boxNodeId === box.boxNodeId,
    )
  }
  return false
}

const filteredItems = computed(() => {
  const items = [
    {
      id: 'edit',
      title: t('actions.edit'),
      icon: 'far fa-pencil',
      show: () => true,
      click: () => {
        dialogCreateBox.value = true
      },
    },
    {
      id: 'delete',
      title: t('actions.delete'),
      icon: 'far fa-trash',
      show: () => true,
      click: async () => {
        const msgOptions = {
          title: `Delete Box "${props.box?.name || props.box?.boxName}"`,
          content: `
          Do you really want to delete the box named <b>"${
            props.box?.name || props.box?.boxName
          }"</b>
          with ID: <b>"${props.box?.boxNodeId}"</b>
          <br />
          <br />
          This action is definitive and all related files, permissions, shares will also be irretrievably lost.
          Existing connections will be disconnected and processes at connected services cannot be continued.
          <br />
          <br />
          The Box will be still billed until the end of this period.
          `,
          cardProps: {
            minWidth: 500,
          },
          msgBoxType: 'checkbox',
          msgBoxTypeFormFieldProps: {
            label:
              'Yes, I accept that all the mentioned data will be irretrievably lost.',
            class: 'text-error',
          },
        }
        const { action, checkbox } = await deepMsgBox.error(msgOptions)
        if (action === 'confirm' && checkbox) {
          try {
            await deepBoxAdminDeepBoxesBoxesAPI.deleteById(
              props.box?.deepBoxNodeId,
              props.box?.boxNodeId,
              { i_am_aware_that_this_is_irreversible: checkbox },
            )
            emit('box:deleted', {
              deepBoxNodeId: props.box?.deepBoxNodeId,
              boxNodeId: props.box?.boxNodeId,
            })
            toast.success(
              `Box: '${
                props.box?.name || props.box?.boxName
              }' was successfully deleted.`,
            )
          } catch {
            toast.error(t('error.error_occurred'))
          }
        }
      },
    },
    {
      id: 'favorites',
      title: isFav(props.box) ? 'Remove from favorites' : 'Add to favorites',
      icon: isFav(props.box) ? 'fas fa-star' : 'far fa-star',
      show: () => true,
      click: () => {
        if (isFav(props.box)) {
          deepBoxDeepBoxesBoxesFavoritesStore.removeFromFavorites(props.box)
          toast.success(
            `Box: '${
              props.box?.name || props.box?.boxName
            }' was successfully removed from favorites.`,
          )
        } else {
          deepBoxDeepBoxesBoxesFavoritesStore.addToFavorites(props.box)
          toast.success(
            `Box: '${
              props.box?.name || props.box?.boxName
            }' was successfully added to favorites.`,
          )
        }
      },
    },
  ]
  return items.filter((i) => i.show())
})

function onClickItem(item) {
  item.click()
}
</script>
