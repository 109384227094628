import { storageApi } from '@/api/interceptors'

interface WindowsClientVersion {
  version: string
  exe: string
}

export const useGlobalStore = defineStore('global', () => {
  const fetchInitialPending = ref(false)
  const boxDetailsDrawer = ref(false)
  const boxDetailsDrawerScrollId = ref<string | null>(null)
  const mobileSearch = ref(false)
  const searchTerm = ref('')
  const boxSearchTerm = ref('')
  const lastSearchTerm = ref<string | null>(null)
  const isSearchbarExpanded = ref(false)
  const fetchWindowsClientVersionPending = ref(false)
  const windowsClientVersion = ref<WindowsClientVersion>({
    version: '',
    exe: '',
  })

  function setBoxDetailsDrawer(show: boolean) {
    boxDetailsDrawer.value = show
  }

  function openBoxDetailsDrawer() {
    boxDetailsDrawer.value = true
  }

  function setBoxDetailsDrawerScrollId(id: string) {
    boxDetailsDrawerScrollId.value = id
  }

  function setBoxSearchTerm(payload: string) {
    boxSearchTerm.value = payload
  }

  function setIsSearchbarExpanded(isExpanded: boolean) {
    isSearchbarExpanded.value = isExpanded
  }

  async function fetchWindowsClientVersion() {
    fetchWindowsClientVersionPending.value = true
    try {
      const response = await storageApi.get(
        import.meta.env.VITE_DEEPBOX_WINDOWS_CLIENT_PATH,
      )
      windowsClientVersion.value = { ...response.data }
    } catch (error) {
      await Promise.reject(error)
    } finally {
      fetchWindowsClientVersionPending.value = false
    }
  }

  return {
    // state
    fetchInitialPending,
    boxDetailsDrawer,
    boxDetailsDrawerScrollId,
    mobileSearch,
    searchTerm,
    boxSearchTerm,
    lastSearchTerm,
    isSearchbarExpanded,
    fetchWindowsClientVersionPending,
    windowsClientVersion,
    // actions
    setBoxDetailsDrawer,
    openBoxDetailsDrawer,
    setBoxDetailsDrawerScrollId,
    setBoxSearchTerm,
    setIsSearchbarExpanded,
    fetchWindowsClientVersion,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useGlobalStore, import.meta.hot))
}
