import { useErrorStore } from '@/stores/error/error'
import { DeepBoxError } from '@/models'

class DeepErrorHandlerService {
  static error(error: Error | DeepBoxError) {
    const errorStore = useErrorStore()
    errorStore.create(error)
  }

  static clear() {
    const errorStore = useErrorStore()
    errorStore.error = undefined // force error to be cleared
    errorStore.$reset()
  }

  static hasError() {
    const errorStore = useErrorStore()
    return !!errorStore.error
  }

  static clearErrorIfExists() {
    if (this.hasError()) {
      this.clear()
    }
  }
}

export default DeepErrorHandlerService
