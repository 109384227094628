import { BoxBreadcrumb } from '@/types/breadcrumbs.ts'
import { constants } from '@/constants'
import i18n from '@/plugins/i18n.ts'
import { PathSegment } from '@/api/types/deepbox/path.ts'
import type { Box, BoxEntry, BoxInfo } from '@/api/types/deepbox/box.ts'
import { type RouteLocationRaw } from 'vue-router'

const pathSegmentsKey: InjectionKey<Ref<PathSegment[] | undefined>> = Symbol(
  'BOX_PATH_SEGMENTS_KEY',
)

const currentBoxKey: InjectionKey<
  MaybeRefOrGetter<Box | BoxInfo | BoxEntry | undefined>
> = Symbol('CURRENT_BOX_KEY')

export function createBoxBreadcrumbsContext(
  pathSegments: Ref<PathSegment[] | undefined>,
  currentBox: MaybeRefOrGetter<Box | BoxInfo | BoxEntry | undefined>,
) {
  provide(pathSegmentsKey, pathSegments)
  provide(currentBoxKey, currentBox)
}

export function useBoxBreadcrumbs() {
  const pathSegments = inject(pathSegmentsKey)
  if (!pathSegments) {
    throw new Error(
      'Please call `createBoxBreadcrumbsContext` on the appropriate parent component',
    )
  }

  const boxBreadcrumbs = computed(() => {
    const breadcrumbs: BoxBreadcrumb[] = []

    toValue(pathSegments)?.forEach((item) => {
      let text = item.displayName
      let to: RouteLocationRaw | string = item.nodeId
      const disabled = false
      switch (item.name) {
        case constants.SECTION_FILES:
          text = i18n.global.t('box_details.navigation.files')
          to = {
            name: 'organization-types-type-boxes-box-files',
          }
          break
        case constants.SECTION_TRASH:
          text = i18n.global.t('box_details.navigation.trash')
          to = {
            name: 'organization-types-type-boxes-box-trash',
          }
          break
        case constants.SECTION_INBOX:
        case constants.SECTION_QUEUE:
          text = i18n.global.t('box_details.navigation.inbox')
          // Won't be ever used as we cannot go deeper in the inbox
          to = 'inbox'
          break

        // no default
      }
      breadcrumbs.push({
        exact: true,
        text,
        to,
        disabled,
        ...item,
      })
    })
    // Disable last item
    if (breadcrumbs.length > 0) {
      breadcrumbs[breadcrumbs.length - 1].disabled = true
    }

    return breadcrumbs
  })

  function getFilterPathSegments(
    items: PathSegment[] | BoxBreadcrumb[],
    box: Box | BoxInfo | BoxEntry,
  ) {
    if (!box || box.boxVariant !== 'private') return items
    return items.filter((s) => {
      return s.name !== constants.SECTION_FILES && box.boxVariant === 'private'
    })
  }

  const currentBox = inject(currentBoxKey)

  // global boxBreadcrumbs by removing the root node if name === 'files'
  const boxBreadcrumbsFiltered = computed(() => {
    const currentBoxValue = toValue(currentBox)
    if (!currentBoxValue) {
      return boxBreadcrumbs.value
    }
    return getFilterPathSegments(boxBreadcrumbs.value, currentBoxValue)
  })

  return {
    pathSegments: toRef(pathSegments),
    boxBreadcrumbs,
    boxBreadcrumbsFiltered,
  }
}
