<template>
  <MenuOrganization
    v-if="deepAdminUsersMeStore.selectedOrganization"
    :hide-menu-icon="
      hideMenuIcon ||
      deepAdminUsersMeStore.getUserSelectableOrganizations.length <= 1
    "
    :is-menu-disabled="
      isMenuDisabled ||
      deepAdminUsersMeStore.getUserSelectableOrganizations.length <= 1
    "
    :organizations="deepAdminUsersMeStore.getUserSelectableOrganizations"
    :selected-organization="deepAdminUsersMeStore.selectedOrganization"
    @change:organization="onChangeOrganization"
  />
</template>
<script lang="ts" setup>
import type { UserMeOrganization } from '@/api/types/deepadmin/users/user.ts'
import MenuOrganization from '@/components/menu/MenuOrganization.vue'
import { useDeepAdminUsersMeStore } from '@/stores/deepadmin/users/users-me.ts'
import { useDeepAdminUsersCompanyAccountingStore } from '@/stores/deepadmin/users/users-company-accounting.ts'

defineProps({
  isMenuDisabled: {
    type: Boolean,
    default: false,
  },
  hideMenuIcon: {
    type: Boolean,
    default: false,
  },
})

const router = useRouter()
const deepAdminUsersCompanyAccountingStore =
  useDeepAdminUsersCompanyAccountingStore()
const deepAdminUsersMeStore = useDeepAdminUsersMeStore()

const selectedOrganizationId = computed(
  () => deepAdminUsersMeStore.selectedOrganizationId || '',
)

watch(
  () => selectedOrganizationId.value,
  (newValue) => {
    if (newValue?.length) {
      deepAdminUsersCompanyAccountingStore.fetchCompanyAccounting(newValue)
    }
  },
  { immediate: true },
)

async function onChangeOrganization(organization: UserMeOrganization) {
  if (selectedOrganizationId.value !== organization.group_id) {
    deepAdminUsersCompanyAccountingStore.$reset()
    deepAdminUsersMeStore.selectOrganization(organization)

    void router.push({
      name: 'organization',
      params: { organization: organization.group_id },
    })
  }
}
</script>
