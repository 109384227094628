<template>
  <v-avatar v-bind="$attrs" :image="image" :size="size" tile />
</template>

<script lang="ts" setup>
defineProps({
  name: {
    type: String,
    default: 'folder-icon',
  },
  size: {
    type: [String, Number],
    default: 20,
  },
  image: {
    type: String,
    default: '/svg/files/folder-20-px.svg',
  },
})
</script>
