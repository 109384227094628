<template>
  <v-form
    v-if="formModel"
    ref="formRef"
    v-model="isFormValid"
    :validate-on="isFormValid === undefined ? 'submit' : 'input'"
    @submit.prevent
  >
    <v-row>
      <v-col v-if="!hiddenFields.includes(FIELDS.NAME)" cols="12">
        <v-text-field
          v-model="formModel.name"
          autofocus
          hide-details="auto"
          label="Name"
          :name="FIELDS.NAME"
          :rules="[ruleRequired]"
        />
      </v-col>
      <v-col v-if="!hiddenFields.includes(FIELDS.VARIANT)" cols="12">
        <v-select
          v-model="formModel.boxVariant"
          clearable
          hide-details="auto"
          :items="boxVariants"
          label="Variant"
          :name="FIELDS.VARIANT"
        />
      </v-col>
      <v-col
        v-if="!hiddenFields.includes(FIELDS.ADD_OWNERS_AS_ORGANIZERS)"
        cols="12"
      >
        <v-checkbox
          v-model="formModel.addOwnersAsOrganizers"
          label="add owners as organizers"
          :name="FIELDS.ADD_OWNERS_AS_ORGANIZERS"
        ></v-checkbox>
      </v-col>
    </v-row>
  </v-form>
</template>

<script lang="ts" setup>
// types & constants
import type { BoxAdd, BoxUpdate } from '@/api/types/deepbox/box.ts'
import { FIELDS, type Fields } from '@/components/box/box-admin/const.ts'
// utilities
import { ruleRequired } from '@deepcloud/field-validators'

defineProps({
  organizationId: {
    type: String,
    default: null,
  },
  hiddenFields: {
    type: Array as PropType<Fields[]>,
    default: null,
  },
})

const formModel = defineModel({
  type: Object as PropType<BoxAdd | BoxUpdate>,
  default: undefined,
})

const isFormValid = ref<boolean>()
const formRef = ref()

const boxVariants = computed(() => [
  {
    value: null,
    title: 'Simple Box',
  },
  {
    value: 'advanced',
    title: 'Advanced Box: costs extra 9.- per month',
  },
  {
    value: 'advanced-per-use',
    title: 'Advanced Box (pay per use)',
  },
])

defineExpose({ formRef })
</script>
