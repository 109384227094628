<template>
  <NodeFolderAvatar
    v-if="node && isNodeTypeFolder(node)"
    image="/svg/illustrations/folder-selected.svg"
    size="180"
  />
  <v-img
    v-else-if="nodeThumbnailUrl"
    :height="height"
    :max-height="maxHeight"
    :max-width="maxWidth"
    :min-height="minHeight"
    :min-width="minWidth"
    :src="nodeThumbnailUrl"
    :width="width"
  >
    <template #placeholder>
      <v-row align="center" class="fill-height ma-0" justify="center">
        <v-progress-circular color="primary-lighten-5" indeterminate />
      </v-row>
    </template>
  </v-img>
  <v-img
    v-else
    height="128"
    :src="`/svg/files/${getNodeIcon(node)}-20-px.svg`"
    width="50"
  />
</template>

<script lang="ts" setup>
import { useNode } from '@/composables/use-node'
import type { Node, NodeInfo } from '@/api/types/deepbox/node'
import type { PathSegment } from '@/api/types/deepbox/path'
import { getNodeIcon } from '@/utils/helpers/nodes.ts'
import NodeFolderAvatar from '@/components/node/NodeFolderAvatar.vue'

const props = defineProps({
  node: {
    type: Object as PropType<Node | PathSegment | NodeInfo>,
    default: () => ({}),
  },
  height: {
    type: [Number, String],
    default: 180,
  },
  maxHeight: {
    type: [Number, String],
    default: null,
  },
  minHeight: {
    type: [Number, String],
    default: null,
  },
  width: {
    type: [Number, String],
    default: null,
  },
  maxWidth: {
    type: [Number, String],
    default: null,
  },
  minWidth: {
    type: [Number, String],
    default: null,
  },
})

const { isNodeTypeFolder } = useNode()

const nodeThumbnailUrl = computed(() => props.node.thumbnailUrl || null)
</script>
