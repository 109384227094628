<template>
  <v-card title="Flow Aufgaben">
    <v-divider />
    <v-card-text class="pa-0">
      <v-list class="pa-0 tasks-list" density="compact" slim>
        <template v-for="task in tasks" :key="task.taskId">
          <v-list-item>
            <template #prepend>
              <v-icon
                v-if="task.icon"
                v-tooltip.bottom="task.taskType"
                :color="task.iconColor"
              >
                {{ `far fa-${task.icon}` }}
              </v-icon>
            </template>
            <template #title>
              <span v-if="task.subject" v-tooltip.bottom="task.subject">
                {{ task.subject }}
              </span>
              <span v-else v-tooltip.bottom="task.definitionName">
                {{ task.definitionName }}
              </span>
            </template>
            <v-list-item-subtitle class="text-truncate">
              <span v-if="task.text" v-tooltip.bottom="task.text">
                {{ task.text }}
              </span>
              <span v-else v-tooltip.bottom="task.boxName">
                {{ task.boxName }}
              </span>
            </v-list-item-subtitle>
            <template #append>
              <v-btn
                class="ml-2"
                color="primary"
                :icon="true"
                size="small"
                slim
                variant="text"
                @click="emit('click:item', { task })"
              >
                <v-icon size="small">fas fa-circle-play</v-icon>
              </v-btn>
            </template>
          </v-list-item>
          <v-divider />
        </template>
        <v-list-item v-if="tasks.length === 0" title="Akt. keine Aufgaben">
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script lang="ts" setup>
import type { Task } from '@/api/types/deepo/peng-task'

defineProps({
  tasks: {
    type: Array as PropType<Task[]>,
    default: () => [],
  },
})

const emit = defineEmits(['click:item'])
</script>
<style lang="scss" scoped>
.tasks-list {
  max-height: 356px;
  overflow-y: auto;
}
</style>
