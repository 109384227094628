<template>
  <v-card flat style="background-color: transparent" v-bind="$attrs">
    <v-card-title
      v-if="$slots.title || title || !expandBtnHidden"
      class="d-flex align-center"
      :style="expandBtnDisabled ? '' : { cursor: 'pointer' }"
      @click.stop="expandBtnDisabled ? undefined : onChangeExpandCard()"
    >
      <slot name="title">
        <div>
          {{ title }}
        </div>
        <v-spacer />
      </slot>
      <v-btn
        v-if="!expandBtnHidden && !expandBtnDisabled"
        :data-test-id="model ? 'collapse' : 'expand'"
        icon
        :size="expandBtnSize"
        slim
        @click.stop="onChangeExpandCard"
      >
        <v-icon :color="expandBtnIconColor" :size="expandBtnIconSize">
          {{ model ? 'fas fa-chevron-up' : 'fas fa-chevron-down' }}
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-divider v-if="showDivider" />
    <v-expand-transition :mode="expandMode">
      <v-card-text v-if="model" :class="contentCls">
        <slot name="content"></slot>
      </v-card-text>
    </v-expand-transition>
  </v-card>
</template>

<script lang="ts" setup>
defineProps({
  title: {
    type: String,
    default: null,
  },
  contentCls: {
    type: String,
    default: 'py-0',
  },
  expandMode: {
    type: String,
    default: 'ease-in-out',
  },
  showDivider: {
    type: Boolean,
    default: false,
  },
  expandBtnIconSize: {
    type: String,
    default: 'small',
  },
  expandBtnIconColor: {
    type: String,
    default: 'grey',
  },
  expandBtnSize: {
    type: String,
    default: 'x-small',
  },
  expandBtnDisabled: {
    type: Boolean,
    default: false,
  },
  expandBtnHidden: {
    type: Boolean,
    default: false,
  },
})

const model = defineModel({ type: Boolean, default: false })

function onChangeExpandCard() {
  model.value = !model.value
}
</script>
