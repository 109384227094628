<template>
  <div v-if="globalStore.boxDetailsDrawer && deepBoxCoreStore.selectedBox">
    <v-navigation-drawer
      v-model="globalStore.boxDetailsDrawer"
      data-test-id="details-sidebar"
      disable-resize-watcher
      disable-route-watcher
      location="right"
      :permanent="!xs"
      :temporary="xs"
      :width="drawerWidth"
    >
      <AppLoader v-if="isLoading" />

      <template v-else-if="isPreviewRouteAndBoxPrivate">
        <v-card-item>
          <template #prepend>
            <v-btn
              density="compact"
              icon="far fa-chevrons-right"
              name="btn-toggle-box-details-drawer"
              variant="text"
              @click="globalStore.boxDetailsDrawer = false"
            ></v-btn>
          </template>
        </v-card-item>
        <v-card-item v-if="displayItem">
          <CoreComments
            :autofocus="deepBoxCommentsStore.size === 0"
            :can-comment="canComment(displayItem as Node) && !isBoxPrivate"
            :node-id="displayItem.nodeId"
            :user="
              isBoxPrivate
                ? {
                    displayName: 'DeepMail',
                    logo: '/svg/deep-mail-icon.svg',
                  }
                : undefined
            "
            @comment:add="onCommentAdd"
          />
        </v-card-item>
      </template>
      <template v-else>
        <v-card-item>
          <template #title>
            <div class="text-subtitle-1">
              {{ t('box_details.details_drawer.details') }}
            </div>
          </template>

          <template #append>
            <v-btn
              density="compact"
              icon="$close"
              name="btn-toggle-box-details-drawer"
              variant="text"
              @click="globalStore.boxDetailsDrawer = false"
            />
          </template>
        </v-card-item>

        <div class="px-2 overflow-y-auto">
          <v-sheet
            class="bg-grey-lighten-4 d-flex align-center justify-center my-2"
            height="200"
            rounded="lg"
            width="100%"
          >
            <v-img
              v-if="nodes.length > 1"
              height="150"
              src="/svg/illustrations/nodes-multiple-selected.svg"
            ></v-img>
            <BoxDetailDrawerNodePreview
              v-else
              max-height="180"
              max-width="180"
              :node="displayItem as Node"
            />
          </v-sheet>
          <div>
            <v-card-title
              v-if="!isPreviewRouteAndBoxPrivate"
              class="text-wrap text-body-1"
            >
              <span v-if="displayItemName">
                {{ displayItemName }}
              </span>
              <span v-else>
                {{ t('labels.n_elements_selected', nodes.length) }}
              </span>
            </v-card-title>

            <template v-if="displayItem">
              <!-- SECTION DETAILS -->
              <div v-if="displayItemNodeId" ref="scrollbarContainerRef">
                <v-divider />
                <CardExpandable
                  v-if="showSectionDetails"
                  :id="constants.BOX_DETAILS_DRAWER_SCROLL_ID_DETAILS"
                  v-model="expandDetails"
                >
                  <template #title>
                    <div class="text-medium-emphasis text-body-2">
                      {{
                        isNodeTypeFolder(displayItem)
                          ? t('box_details.details_drawer.details_folder')
                          : t('box_details.details_drawer.details_file')
                      }}
                    </div>
                    <v-spacer />
                  </template>
                  <template #content>
                    <v-list class="pt-0" density="compact" slim>
                      <template
                        v-for="detailsItem in detailsItems"
                        :key="detailsItem.id"
                      >
                        <v-list-item v-if="detailsItem.show" class="px-0">
                          <v-list-item-subtitle>
                            {{ detailsItem.label }}
                          </v-list-item-subtitle>
                          <v-list-item-title>
                            {{ detailsItem.value }}
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                    </v-list>
                  </template>
                </CardExpandable>
              </div>
              <!-- /SECTION DETAILS -->

              <!-- SECTION TAGS -->
              <template v-if="showSectionTags">
                <v-divider v-if="displayItemNodeId" />
                <CardExpandable
                  v-if="displayItemNodeId"
                  :id="constants.BOX_DETAILS_DRAWER_SCROLL_ID_TAGS"
                  v-model="expandTags"
                  :expand-btn-disabled="displayItem.tags?.length === 0"
                  :expand-btn-hidden="displayItem.tags?.length === 0"
                  :loading="deepBoxTagsStore.assignTagPending"
                >
                  <template #title>
                    <div class="text-medium-emphasis text-body-2">
                      {{ t('box_details.details_drawer.tags') }}
                    </div>
                    <v-spacer />
                    <div
                      v-if="displayItem.tags && displayItem.tags.length > 0"
                      class="text-medium-emphasis text-body-2"
                    >
                      {{ displayItem.tags.length }}
                      {{
                        t(
                          'box_details.details_drawer.sections.tags.counter',
                          displayItem.tags.length,
                        )
                      }}
                    </div>
                    <v-btn
                      v-else
                      class="mt-2"
                      color="primary"
                      :disabled="
                        deepBoxTagsStore.assignTagPending || disableBtnAddTags
                      "
                      name="btn-box-details-drawer-tags-assign"
                      variant="plain"
                      @click.stop="showDialogNodeAssignTag = true"
                    >
                      {{ t('box_details.details_drawer.sections.tags.assign') }}
                    </v-btn>
                  </template>
                  <template #content>
                    <CoreTag
                      v-for="tag in displayItem.tags"
                      :key="tag.key"
                      class="mr-1 my-2"
                      closable
                      :tag="tag"
                      @click="searchByTag(tag.key)"
                      @click:close="onRemoveTag(tag)"
                    />
                    <v-btn
                      class="pa-0 my-2"
                      color="primary"
                      :disabled="deepBoxTagsStore.assignTagPending"
                      name="btn-box-details-drawer-tags-assign"
                      variant="plain"
                      @click.stop="showDialogNodeAssignTag = true"
                    >
                      {{ t('box_details.details_drawer.sections.tags.assign') }}
                    </v-btn>
                  </template>
                </CardExpandable>
              </template>
              <!-- /SECTION TAGS -->

              <!-- SECTION DEEP_PAY PAYMENTS HISTORY -->
              <template v-if="showSectionDeepPay">
                <DeepPayPaymentsHistoryCardExpandable
                  v-if="deepBoxBoxInfoStore.boxInfo"
                  :box-id="boxId"
                  :mime-type="displayItem?.mimeType || ''"
                  :node-id="displayItem.nodeId"
                  :origin-organization-id="
                    deepBoxBoxInfoStore.boxInfo.company.companyId
                  "
                  :type-id="typeId"
                />
              </template>
              <!-- /SECTION DEEP_PAY PAYMENTS HISTORY -->

              <!-- SECTION COMMENTS -->
              <template v-if="showSectionComments">
                <v-divider
                  v-if="
                    showSectionDetails || showSectionTags || showSectionDeepPay
                  "
                />
                <!-- SECTION COMMENTS -->
                <CardExpandable
                  :id="constants.BOX_DETAILS_DRAWER_SCROLL_ID_COMMENTS"
                  v-model="expandComments"
                  :expand-btn-disabled="
                    deepBoxCommentsStore.size === 0 && !expandComments
                  "
                  :expand-btn-hidden="
                    (deepBoxCommentsStore.size === 0 && !expandComments) ||
                    isBoxPrivate
                  "
                >
                  <template v-if="!isBoxPrivate" #title>
                    <div class="text-body-2 text-medium-emphasis">
                      {{ t('box_details.details_drawer.comments') }}
                    </div>
                    <v-btn
                      class="ml-1"
                      :icon="true"
                      name="btn-box-details-drawer-comment-reload"
                      size="x-small"
                      slim
                      @click.stop="onCommentsFetch"
                    >
                      <v-icon size="small">far fa-rotate</v-icon>
                    </v-btn>
                    <v-spacer />
                    <v-btn
                      v-if="deepBoxCommentsStore.size === 0 && !expandComments"
                      color="primary"
                      data-test-id="file-details-comment-add"
                      :disabled="deepBoxTagsStore.assignTagPending"
                      name="btn-box-details-drawer-comment-add"
                      slim
                      variant="plain"
                      @click.stop="expandComments = true"
                    >
                      {{
                        t(
                          'box_details.details_drawer.sections.comments.text_add',
                        )
                      }}
                    </v-btn>
                    <div v-else class="text-body-2 text-medium-emphasis">
                      {{ deepBoxCommentsStore.size }}
                      {{
                        t(
                          'box_details.details_drawer.sections.comments.counter',
                          deepBoxCommentsStore.size,
                        )
                      }}
                    </div>
                  </template>
                  <template #content>
                    <CoreComments
                      :autofocus="deepBoxCommentsStore.size === 0"
                      :can-comment="
                        canComment(displayItem as Node) && !isBoxPrivate
                      "
                      :node-id="displayItem.nodeId"
                      :user="
                        isBoxPrivate
                          ? {
                              displayName: 'DeepMail',
                              logo: '/svg/deep-mail-icon.svg',
                            }
                          : undefined
                      "
                      @comment:add="onCommentAdd"
                    />
                  </template>
                </CardExpandable>
                <!--  /SECTION COMMENTS -->
              </template>
              <!-- /SECTION COMMENTS -->
            </template>
          </div>
        </div>
      </template>
    </v-navigation-drawer>
    <!-- Assign Tag Dialog -->
    <DialogNodeAssignTag
      v-model="showDialogNodeAssignTag"
      :box-id="boxId"
      :nodes="[displayItem]"
      :organization-id="organizationId"
      :type-id="typeId"
    />
    <!-- /Assign Tag Dialog -->
  </div>
</template>
<script lang="ts" setup>
import first from 'lodash/first'
import last from 'lodash/last'
import CardExpandable from '@/components/cards/CardExpandable.vue'
import BoxDetailDrawerNodePreview from '@/components/box/BoxDetailDrawerNodePreview.vue'
import CoreTag from '@/components/core/CoreTag.vue'
import CoreComments from '@/components/core/comments/CoreComments.vue'
import DeepPayPaymentsHistoryCardExpandable from '@/components/deeppay/paymentshistory/DeepPayPaymentsHistoryCardExpandable.vue'

import { constants } from '@/constants/constants'

import { useGlobalStore } from '@/stores/global/global'
import { useDeepBoxCommentsStore } from '@/stores/deepbox/nodes/comments'
import { useDeepBoxTagsStore } from '@/stores/deepbox/deepboxes/tags'
import { useDeepBoxCoreStore } from '@/stores/deepbox/core'
import { deepBoxNodesThumbnailUrlAPI } from '@/api/deepbox/nodes/nodes-thumbnail-url'
import { toast } from 'vue-sonner'
import type { VNavigationDrawer } from 'vuetify/components'
import { type Node } from '@/api/types/deepbox/node'
import type { Path } from '@/api/types/deepbox/path'
import type { Tag } from '@/api/types/deepbox/tag'
import DialogNodeAssignTag from '@/components/dialogs/DialogNodeAssignTag.vue'
import { useNode } from '@/composables/use-node'
import { useDisplay, useGoTo } from 'vuetify'
import { useDeepBoxDeepBoxesBoxesNodesStore } from '@/stores/deepbox/deepboxes/boxes/nodes.ts'
import { useNodePolicy } from '@/composables/use-node-policy.ts'
import { useNodeTags } from '@/composables/use-node-tags.ts'
import { useNodeSection } from '@/composables/use-node-section.ts'
import { useDeepBoxBoxInfoStore } from '@/stores/deepbox/box-info.ts'
import { isIntEnv, isLocalOrDevEnv } from '@/utils/helpers/env.ts'
import { isNodeImage, isNodePdf } from '@/utils/helpers/nodes.ts'
import { formatDateTimeWithoutSeconds } from '@deepcloud/deep-ui-lib'
import { useBox } from '@/composables/use-box.ts'
import { useBoxBreadcrumbs } from '@/composables/use-box-breadcrumbs.ts'
import AppLoader from '@/components/app/AppLoader.vue'

const props = defineProps({
  hidePreview: {
    type: Boolean,
    default: false,
  },
  organizationId: {
    type: String,
    default: null,
  },
  typeId: {
    type: String,
    required: true,
  },
  boxId: {
    type: String,
    required: true,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  nodes: {
    type: Array as PropType<Node[]>,
    required: true,
  },
})

const router = useRouter()
const route = useRoute()
const { t } = useI18n()
const { xs } = useDisplay()

const expandDetails = ref(true)
const expandComments = ref(false)
const expandTags = ref(false)
const thumbnailUrl = ref('')
const showDialogNodeAssignTag = ref(false)

const deepBoxCoreStore = useDeepBoxCoreStore()
const deepBoxCommentsStore = useDeepBoxCommentsStore()
const deepBoxTagsStore = useDeepBoxTagsStore()
const globalStore = useGlobalStore()
const deepBoxDeepBoxesBoxesNodesStore = useDeepBoxDeepBoxesBoxesNodesStore()
const deepBoxBoxInfoStore = useDeepBoxBoxInfoStore()

const { isBoxPrivate } = useBox(computed(() => deepBoxCoreStore.selectedBox))

onUnmounted(() => {
  deepBoxCommentsStore.$reset()
})

function isFirstItemRoot(item) {
  return ['inbox', 'files', 'trash'].includes(item.name)
}

const isPreviewRoute = computed(
  () => route.name === 'node-preview' || route.name === 'link-preview',
)

const isPreviewRouteAndBoxPrivate = computed(
  () => isPreviewRoute.value && isBoxPrivate.value,
)

const { boxBreadcrumbsFiltered } = useBoxBreadcrumbs()

const displayItem = computed(() => {
  if (props.nodes.length > 1) return undefined

  interface DisplayItem extends Partial<Node> {
    thumbnailUrl: string
    parentPath: Path | undefined
  }

  const item: DisplayItem = {
    displayName: '',
    type: 'folder',
    thumbnailUrl: thumbnailUrl.value,
    tags: [],
    parentPath: undefined,
  } as DisplayItem

  if (props.nodes[0]) {
    Object.assign(item, props.nodes[0])
  } else if (deepBoxDeepBoxesBoxesNodesStore.selectedNodes.length === 0) {
    Object.assign(item, {
      ...last(boxBreadcrumbsFiltered.value),
    })
    if (boxBreadcrumbsFiltered.value.length === 1 && isFirstItemRoot(item)) {
      item.displayName = t(`box_details.details_drawer.${item.name}`)
    }
  } else {
    Object.assign(item, last(deepBoxDeepBoxesBoxesNodesStore.selectedNodes))
  }
  return item
})

const disableBtnAddTags = computed(
  () => props.typeId === undefined || props.boxId === undefined,
)

const { getNodeSectionTranslation } = useNodeSection()
const displayItemName = computed(() =>
  displayItem.value && displayItem.value.displayName
    ? getNodeSectionTranslation(displayItem.value.displayName)
    : undefined,
)

const nodeLocation = computed(() => {
  const firstBoxDetailsBreadCrumbsItem = first(boxBreadcrumbsFiltered.value)
  if (!firstBoxDetailsBreadCrumbsItem) return null

  if (firstBoxDetailsBreadCrumbsItem.name) {
    if (isFirstItemRoot(firstBoxDetailsBreadCrumbsItem)) {
      return t(
        `box_details.details_drawer.${firstBoxDetailsBreadCrumbsItem.name}`,
      )
    } else {
      return firstBoxDetailsBreadCrumbsItem.displayName
    }
  }
  if (displayItem.value?.parentPath) {
    const firstParentPathSegmentItemName = first(
      displayItem.value?.parentPath?.segments,
    )?.name
    if (firstParentPathSegmentItemName) {
      return t(`box_details.details_drawer.${firstParentPathSegmentItemName}`)
    }
  }
  return null
})

const detailsItems = computed(() => {
  return [
    {
      id: 'type',
      show: displayItem.value?.type,
      label: t('box_details.details_drawer.category'),
      value: t(`box_details.details_drawer.${displayItem.value?.type}`),
    },
    {
      id: 'location',
      show: nodeLocation.value,
      label: t('box_details.details_drawer.location'),
      value: nodeLocation.value,
    },
    {
      id: 'display_name',
      show: displayItem.value?.ownerUser?.displayName,
      label: t('box_details.details_drawer.owner'),
      value: displayItem.value?.ownerUser?.me
        ? t('box_details.details_drawer.me')
        : displayItem.value?.ownerUser?.displayName,
    },
    {
      id: 'modified_time',
      show: displayItem.value?.modified?.time,
      label: t('box_details.details_drawer.modified'),
      value: displayItem.value?.modified?.time
        ? formatDateTimeWithoutSeconds(displayItem.value.modified.time)
        : undefined,
    },
    {
      id: 'created_time',
      show: displayItem.value?.created?.time,
      label: t('box_details.details_drawer.created'),
      value: displayItem.value?.created?.time
        ? formatDateTimeWithoutSeconds(displayItem.value.created.time)
        : undefined,
    },
  ]
})

const displayItemNodeId = computed(() => displayItem.value?.nodeId)

const isDisplayItemTypeFolder = computed(
  () => displayItem.value?.type === constants.NODE_TYPE_FOLDER,
)

const { canComment, canTag } = useNodePolicy()

const showSectionComments = computed(
  () =>
    !isDisplayItemTypeFolder.value &&
    (canComment(displayItem.value as Node) || isBoxPrivate.value),
)

const itemTags = computed(() => displayItem.value?.tags || [])

const itemTagsCount = computed(() => itemTags.value?.length)

function searchByTag(tagKey: string) {
  deepBoxTagsStore.setTag(tagKey)
  if (props.typeId && props.boxId) {
    router.push({
      name: 'organization-types-type-boxes-box-search',
      params: {
        type: props.typeId,
        box: props.boxId,
      },
      query: { tags: tagKey },
    })
  }
}

function clear() {
  deepBoxCommentsStore.clearCommentsStore()
  expandTags.value = false
  expandComments.value = false
}

const { isNodeTypeFolder } = useNode()

async function fetchDetailsData() {
  if (deepBoxCommentsStore.fetchNodeCommentsPending) return
  expandTags.value = itemTags.value.length > 0
  if (
    displayItem.value &&
    displayItem.value.nodeId &&
    !isNodeTypeFolder(displayItem.value as Node)
  ) {
    try {
      const res = await deepBoxNodesThumbnailUrlAPI.get(
        displayItem.value.nodeId,
      )
      if (res.data) {
        thumbnailUrl.value = res.data
      }
    } catch (e) {
      console.log(e)
    }
  }
  if (showSectionComments.value) {
    await onCommentsFetch()
    if (
      globalStore.boxDetailsDrawerScrollId ||
      deepBoxCommentsStore.comments.length > 0
    ) {
      expandComments.value = true
    }
    // this setTimeout allow the scroll to works because expand has an
    // effect and scroll only works when card is opened
    setTimeout(async () => {
      if (globalStore.boxDetailsDrawerScrollId) {
        const id = `#${globalStore.boxDetailsDrawerScrollId}`
        await goToSection(id)
      }
    }, 100)
  }
}

const scrollbarContainerRef = ref<HTMLElement | null>(null)

const goTo = useGoTo()

async function goToSection(id: string) {
  const options = {
    duration: 300,
    offset: -60,
    easing: 'easeInOutCubic',
    container: undefined,
  }
  if (scrollbarContainerRef.value) {
    options.container = scrollbarContainerRef.value
  }
  await goTo(id, options)
}

async function onCommentsFetch() {
  if (displayItemNodeId.value) {
    deepBoxCommentsStore.clearNodeComments()
    deepBoxCommentsStore.resetApiParams()
    await deepBoxCommentsStore.fetchNodeComments({
      nodeId: displayItemNodeId.value,
    })
  }
}

async function onCommentAdd() {
  const id = `#${constants.BOX_DETAILS_DRAWER_SCROLL_ID_COMMENTS}`
  await goToSection(id)
}

async function onRemoveTag(tag: Tag) {
  if (!displayItemNodeId.value || !displayItem.value?.tags) return
  let tags = [...displayItem.value.tags]
  tags = tags.filter((displayItemTag) => displayItemTag.key !== tag.key)
  try {
    await deepBoxTagsStore.updateNodeTags({
      nodeId: displayItemNodeId.value,
      tags,
    })
    const { updateNodeTags } = useNodeTags()
    updateNodeTags({
      nodeId: displayItemNodeId.value,
      tags,
    })
  } catch {
    toast.error(t('error.error_occurred'))
  }
}

watch(
  () => displayItemNodeId.value,
  () => {
    clear()
    if (displayItem.value) {
      if (
        isNodeImage(displayItem.value as Node) ||
        isNodePdf(displayItem.value as Node)
      ) {
        displayItem.value.thumbnailUrl = thumbnailUrl.value
      } else {
        displayItem.value.thumbnailUrl = ''
      }
    }
    if (globalStore.boxDetailsDrawer) {
      fetchDetailsData()
    }
  },
  { immediate: true },
)

watch(
  () => globalStore.boxDetailsDrawer,
  (newValue) => {
    if (newValue) {
      fetchDetailsData()
    } else {
      globalStore.setBoxDetailsDrawerScrollId('')
      clear()
    }
    if (displayItem.value) {
      if (
        isNodeImage(displayItem.value as Node) ||
        isNodePdf(displayItem.value as Node)
      ) {
        displayItem.value.thumbnailUrl = thumbnailUrl.value
      } else {
        displayItem.value.thumbnailUrl = ''
      }
    }
  },
)

watch(
  () => itemTagsCount.value,
  (newValue) => {
    expandTags.value = newValue > 0
  },
)

const DRAWER_DEFAULT_WIDTH = 320
const drawerWidth = ref(DRAWER_DEFAULT_WIDTH)

function resetDrawerWidth() {
  drawerWidth.value = DRAWER_DEFAULT_WIDTH
}

watch(
  () => globalStore.boxDetailsDrawer,
  (newValue) => {
    if (newValue) {
      if (xs.value) {
        drawerWidth.value = window.innerWidth
      } else {
        resetDrawerWidth()
      }
    } else {
      resetDrawerWidth()
    }
  },
)

watch(
  () => isBoxPrivate.value,
  (newValue) => {
    if (newValue) {
      drawerWidth.value = 256
    } else {
      resetDrawerWidth()
    }
  },
)

const showSectionDetails = computed(() => !isBoxPrivate.value)

const showSectionTags = computed(
  () => displayItemNodeId.value && canTag(displayItem.value as Node),
)

const showSectionDeepPay = computed(
  () =>
    (isLocalOrDevEnv() || isIntEnv()) &&
    deepBoxBoxInfoStore.boxInfo?.company?.companyId &&
    displayItemNodeId.value &&
    !isBoxPrivate.value,
)
</script>
<style scoped lang="scss">
:deep(.v-navigation-drawer__content) {
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
}
</style>
