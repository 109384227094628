import { useNode } from '@/composables/use-node'
import type { Tag } from '@/api/types/deepbox/tag'

export function useNodeTags() {
  // stores
  const { getNodeById } = useNode()

  // UPDATE NODE TAGS
  function updateNodeTags(payload: { nodeId: string; tags: Tag[] }) {
    const node = getNodeById(payload.nodeId)
    if (node) {
      node.tags = payload.tags
    }
  }

  return {
    updateNodeTags,
  }
}
