// stores
import { useDeepBoxDeepBoxesBoxesNodesStore } from '@/stores/deepbox/deepboxes/boxes/nodes'
import { useDeepBoxBoxInfoStore } from '@/stores/deepbox/box-info.ts'
import { useDeepBoxSearchStore } from '@/stores/deepbox/search.ts'

// composables
import { toast } from 'vue-sonner'

// types & constants
import type { ContentData } from '@/api/types/deepbox/globals.ts'
import { constants } from '@/constants'
import DeepBoxApiErrorMsg from '@/constants/api/deep-box-api'
import type { PathSegment } from '@/api/types/deepbox/path.ts'
import type { HttpClientResponse } from '@/api/http-client.ts'
import type { SearchData } from '@/api/types/deepbox/search.ts'

// apis
import { deepBoxNodesMoveAPI } from '@/api/deepbox/nodes/nodes-move.ts'
import { deepBoxNodesInfoAPI } from '@/api/deepbox/nodes/nodes-info.ts'

export function useNodeMove() {
  // stores
  const deepBoxDeepBoxesBoxesNodesStore = useDeepBoxDeepBoxesBoxesNodesStore()
  const deepBoxBoxInfoStore = useDeepBoxBoxInfoStore()
  const deepBoxSearchStore = useDeepBoxSearchStore()

  const { t } = useI18n()

  const moveNodePending = ref(false)

  async function moveNodes(payload: {
    section: string
    targetParentNode: PathSegment
    nodeIds: string[]
  }) {
    console.log('moveNodes', payload)
    moveNodePending.value = true
    try {
      const { targetParentNode, nodeIds, section } = payload
      const targetParentNodeId = targetParentNode.nodeId
      const params = { targetParentNodeId }

      const promises: Promise<HttpClientResponse<never>>[] = []

      let successMovedNodesCount = 0
      const handleApiResponse = (nodeId: string) => {
        // update current nodes state
        if (
          section &&
          [
            constants.SECTION_QUEUE,
            constants.SECTION_FILES,
            constants.SECTION_FILES_NODE,
            constants.SECTION_TRASH,
            constants.SECTION_TRASH_NODE,
          ].includes(section)
        ) {
          let dataSet
          let nodeIndex
          if (
            deepBoxDeepBoxesBoxesNodesStore.data &&
            deepBoxDeepBoxesBoxesNodesStore.data.nodes
          ) {
            dataSet = {
              ...deepBoxDeepBoxesBoxesNodesStore.data,
            } as ContentData
            nodeIndex = dataSet.nodes.findIndex(
              (node) => node.nodeId === nodeId,
            )
            if (nodeIndex > -1) {
              dataSet.nodes.splice(nodeIndex, 1)
              dataSet.size -= 1
            }
          }

          if (targetParentNode.mimeType === constants.MIME_TYPE_INBOX) {
            // target is current box inbox
            if (
              deepBoxDeepBoxesBoxesNodesStore.rootNodes.queue?.nodeId ===
              targetParentNode.nodeId
            ) {
              if (deepBoxDeepBoxesBoxesNodesStore.data) {
                deepBoxDeepBoxesBoxesNodesStore.data.size += 1
              }

              // update BoxInfo `queueCount` in case some files are moved to INBOX
              deepBoxBoxInfoStore.increaseBoxInfoQueueCounter(1)
            } else {
              if (section === constants.SECTION_QUEUE) {
                deepBoxBoxInfoStore.decreaseBoxInfoQueueCounter(1)
              }
            }
          }

          if (dataSet) {
            deepBoxDeepBoxesBoxesNodesStore.data = dataSet
          }
        }

        // check if the moved node is within searchResults
        const dataSetSearchResults = {
          ...deepBoxSearchStore.searchResults,
        } as SearchData
        const index = dataSetSearchResults?.items?.findIndex(
          (item) => item.nodeId === nodeId,
        )
        if (index >= 0) {
          // if the node was already in trash, remove it from the list
          dataSetSearchResults.items[index].parentPath.segments = [
            targetParentNode,
          ]
          deepBoxSearchStore.searchResults = dataSetSearchResults
        }

        successMovedNodesCount += 1
      }

      const errorsList = []

      // Iterate through all the nodeIds, and resolve the promise once all the requests have been fulfilled
      nodeIds.forEach((nodeId) => {
        // add nodeId to the config, to get it again on then() when the promise is resolved
        promises.push(
          deepBoxNodesMoveAPI
            .post(nodeId, params, {
              nodeId,
              targetParentNodeId,
            })
            .then(() => {
              handleApiResponse(nodeId)
            })
            .catch((e) => {
              errorsList.push({ nodeId, e })
            }),
        )
      })

      await Promise.all(promises)

      // handle rejected promises
      const errorsMoveRecursive = []
      const errorsMoveProhibitedGenericFolder = []
      const errorsMoveProhibitedSharedFolder = []
      const errorsMoveOther = []
      errorsList.forEach(({ e }) => {
        const errorStatusCode = e?.response?.status
        const errorData = e.response?.data
        const errorDataMessageId = errorData?.messageId

        if (errorStatusCode === 422) {
          switch (errorDataMessageId) {
            case DeepBoxApiErrorMsg.NODE_MOVE_OUT_OF_BOX_PROHIBITED_GENERIC_FOLDER:
              errorsMoveProhibitedGenericFolder.push(e)
              break

            case DeepBoxApiErrorMsg.NODE_MOVE_OUT_OF_BOX_PROHIBITED_SHARED_FOLDER:
              errorsMoveProhibitedSharedFolder.push(e)
              break
          }
        } else if (
          errorData &&
          errorDataMessageId === DeepBoxApiErrorMsg.ERROR_MOVE_RECURSIVE
        ) {
          errorsMoveRecursive.push(e)
        } else {
          errorsMoveOther.push(e)
        }
      })

      if (errorsMoveRecursive.length > 0) {
        toast.error(
          t(
            'api.deepbox.errors.node.move.recursive',
            errorsMoveRecursive.length,
          ),
        )
      }

      if (errorsMoveProhibitedGenericFolder.length > 0) {
        toast.error(
          t(
            'api.deepbox.errors.node.move.out-of-box.prohibited.generic-folder',
            errorsMoveProhibitedGenericFolder.length,
          ),
        )
      }

      if (errorsMoveProhibitedSharedFolder.length > 0) {
        toast.error(
          t(
            'api.deepbox.errors.node.move.out-of-box.prohibited.shared-folder',
            errorsMoveProhibitedSharedFolder.length,
          ),
        )
      }

      if (errorsMoveOther.length > 0) {
        toast.error(t('error.error_occurred'))
      }

      // if all the nodes have been processed, resolve the global promise
      return Promise.resolve(successMovedNodesCount)
    } finally {
      moveNodePending.value = true
    }
  }

  async function refreshCurrentNodesOnMove(
    targetId: string,
    sourceNodeIds: string[],
  ) {
    for (const sourceNodeId of sourceNodeIds) {
      const sourceNodeIdExists =
        deepBoxDeepBoxesBoxesNodesStore.data?.nodes.find(
          (n) => n.nodeId === sourceNodeId,
        )
      if (sourceNodeIdExists) {
        // remove node from current view
        deepBoxDeepBoxesBoxesNodesStore.removeNodeState(sourceNodeId)
      } else {
        // fetch node and add it to the current view if targetId === current view node id
        if (targetId === deepBoxDeepBoxesBoxesNodesStore.getCurrentRootNodeId) {
          const { data } = await deepBoxNodesInfoAPI.get(sourceNodeId)
          if (data) {
            deepBoxDeepBoxesBoxesNodesStore.addNodeState(data.node)
          }
        }
      }
    }
  }

  return {
    moveNodePending,
    moveNodes,
    refreshCurrentNodesOnMove,
  }
}
