import cloneDeep from 'lodash/cloneDeep'

// based on:
// https://stackoverflow.com/questions/71690883/pinia-reset-alternative-when-using-setup-syntax/71760032#71760032
// https://dev.to/the_one/pinia-how-to-reset-stores-created-with-functionsetup-syntax-1b74
export default function resetStore({ store }: PiniaPluginContext) {
  // Deep clone the initial state to avoid reference issues
  const initialState = cloneDeep(store.$state)
  store.$reset = () => {
    // it's important to deep-copy the state again in order to remove references to the copy itself.
    store.$reset = () => store.$patch(cloneDeep(initialState))
  }
}
