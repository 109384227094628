<template>
  <v-app id="app">
    <NotificationsNewsDrawer
      v-if="
        isLocalOrDevEnv() &&
        isAuthenticated &&
        settingsDevStore.canModeFeature('NEWS_LIST')
      "
    />
    <DevSettingsDrawer v-if="isLocalOrDevEnv()" />

    <ContainerCenteredFull v-if="globalStore.fetchInitialPending">
      <AppLoaderLogo />
    </ContainerCenteredFull>
    <template v-else>
      <LayoutError
        v-if="errorStore.error"
        :error="errorStore.error"
        :organization="deepAdminUsersMeStore.selectedOrganization"
      />
      <router-view v-else />
    </template>
    <Toaster position="top-right" rich-colors />
    <DeepLoader />
    <ReloadPrompt v-if="!isLocalEnv()" />
    <!-- Overused subscription blocking overlay -->
    <OverlayOverusedSubscription />
    <!-- /Overused subscription blocking overlay -->
  </v-app>
</template>
<script lang="ts" setup>
// components
import AppLoaderLogo from '@/components/app/AppLoaderLogo.vue'
import DevSettingsDrawer from '@/components/dev/DevSettingsDrawer.vue'
import ContainerCenteredFull from '@/components/containers/ContainerCenteredFull.vue'
import LayoutError from '@/layouts/LayoutError.vue'
import NotificationsNewsDrawer from '@/components/notifications/NotificationsNewsDrawer.vue'
import ReloadPrompt from '@/components/ReloadPrompt.vue'
import OverlayOverusedSubscription from '@/components/overlays/OverlayOverusedSubscription.vue'
import { Toaster } from 'vue-sonner'
import { DeepLoader, useQuery } from '@deepcloud/deep-ui-lib'

// stores
import { useDeepAdminUsersMeStore } from '@/stores/deepadmin/users/users-me'
import { useErrorStore } from '@/stores/error/error'
import { useGlobalStore } from '@/stores/global/global'
import { useSettingsDevStore } from '@/stores/settings/settings-dev'

// composables
import { useAppLocale } from '@/composables/use-app-locale.ts'
import { useAppTheme } from '@/composables/use-app-theme.ts'
import { useHead } from '@unhead/vue'
import { useKeycloak } from '@/composables/auth/use-keycloak'
import { useDialogSessionInvalid } from '@/composables/use-dialog-session-invalid'

// types & constants
import { LS_LOCALE_KEY } from '@/constants/auth'
import { constants } from '@/constants'
import { isLocalEnv, isLocalOrDevEnv } from '@/utils/helpers/env'

const errorStore = useErrorStore()
const deepAdminUsersMeStore = useDeepAdminUsersMeStore()
const globalStore = useGlobalStore()
const settingsDevStore = useSettingsDevStore()

const { setCurrentTheme } = useAppTheme()
setCurrentTheme()

const route = useRoute()
const { t } = useI18n()

const query = useQuery<{
  lang: string
}>()

const { isAuthenticated, user } = useKeycloak()
const { changeAppLocale } = useAppLocale()
useDialogSessionInvalid()

watch(
  () => query.value.lang,
  (newValue) => {
    if (newValue) {
      changeAppLocale(newValue)
    }
  },
  { immediate: true },
)

if (isAuthenticated.value && user.value) {
  changeAppLocale(user.value.locale)
} else {
  // if user is not auth set custom locale from localStorage or set the locale from the browser
  const customLocale =
    localStorage.getItem(`${LS_LOCALE_KEY}`) || navigator.language
  if (customLocale) {
    changeAppLocale(customLocale)
  }
}

useHead(() => {
  let title = `${constants.DEFAULT_APP_TITLE}`
  if (route.meta?.titleKey) {
    title = `${t(route.meta?.titleKey)} - ${constants.DEFAULT_APP_TITLE}`
  }

  return {
    title,
  }
})
</script>
