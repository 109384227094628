<template>
  <DeepDialog
    v-model="model"
    :loading="isLoadingBoxData"
    :max-width="500"
    :title="currentBoxData ? `Edit box (${currentBoxData.name})` : 'Add box'"
    v-bind="$attrs"
    @keydown.enter="onSave"
  >
    <template #activator="activatorProps">
      <slot name="activator" v-bind="activatorProps"></slot>
    </template>
    <template #content>
      <BoxAdminCreateForm
        ref="boxAdminCreateFormRef"
        v-model="formModel"
        :hidden-fields="currentBoxData ? ['ADD_OWNERS_AS_ORGANIZERS'] : []"
      />
    </template>
    <template #actions>
      <v-spacer />
      <VBtnDev @click="onDevBtnClick" />
      <VBtnPrimary
        :disabled="currentBoxData ? !hasChanges : false"
        :loading="isLoading"
        @click="onSave"
      >
        {{ t('buttons.save') }}
      </VBtnPrimary>
    </template>
  </DeepDialog>
</template>

<script lang="ts" setup>
// components
import BoxAdminCreateForm from './BoxAdminCreateForm.vue'
import { DeepDialog } from '@deepcloud/deep-ui-lib'

// composables
import { toast } from 'vue-sonner'

// apis
import { deepBoxAdminDeepBoxesBoxesAPI } from '@/api/deepbox/admin/admin-deepboxes-boxes.ts'

// types & constants
import type { BoxAdd, BoxUpdate } from '@/api/types/deepbox/box.ts'
import type { AdminBox } from '@/api/types/deepbox/admin.ts'

const props = defineProps({
  typeId: {
    type: String,
    required: true,
  },
  boxId: {
    type: String,
    default: undefined,
  },
})

const model = defineModel({ type: Boolean, default: false })
const emit = defineEmits(['box:updated', 'box:added'])

const { t } = useI18n()

const FORM_MODEL = {
  name: '',
  boxVariant: null,
}

const formModel = ref<BoxAdd | BoxUpdate>({
  ...FORM_MODEL,
})

const currentBoxData = ref<AdminBox>()

const isLoadingBoxData = ref(false)

async function onGetBoxData() {
  isLoadingBoxData.value = true
  try {
    const res = await deepBoxAdminDeepBoxesBoxesAPI.getById(
      props.typeId,
      props.boxId,
    )
    currentBoxData.value = res.data
    formModel.value = {
      name: currentBoxData.value?.name,
      boxVariant: currentBoxData.value?.boxVariant,
    }
  } catch (error) {
    console.error(error)
  } finally {
    isLoadingBoxData.value = false
  }
}

const hasChanges = computed(() => {
  if (!currentBoxData.value) return false
  let changed = false
  if (currentBoxData.value && formModel.value) {
    Object.keys(formModel.value).forEach((field) => {
      const valueA = currentBoxData.value[field]
      const valueB = formModel.value[field]

      const changes = valueA !== valueB
      if (changes) {
        changed = true
      }
    })
  }
  return changed
})

const boxAdminCreateFormRef = ref<typeof BoxAdminCreateForm>()

function clear() {
  if (!boxAdminCreateFormRef.value) return
  boxAdminCreateFormRef.value.formRef.reset()
  formModel.value = { ...FORM_MODEL }
}

watch(
  () => model.value,
  (newValue, oldValue) => {
    if (newValue && props.boxId) {
      onGetBoxData()
    } else if (newValue) {
      formModel.value = {
        ...formModel.value,
        addOwnersAsOrganizers: true,
      }
    }
    if (oldValue && !newValue) {
      clear()
    }
  },
  { immediate: true },
)

const isLoading = ref(false)

async function onSave() {
  if (!boxAdminCreateFormRef.value) return
  const { valid } = await boxAdminCreateFormRef.value.formRef.validate()
  if (valid) {
    isLoading.value = true
    const data = { ...formModel.value }
    if (data.boxVariant === null) {
      delete data.boxVariant
    }
    try {
      if (currentBoxData.value) {
        await deepBoxAdminDeepBoxesBoxesAPI.putById(
          props.typeId,
          currentBoxData.value?.boxNodeId,
          data,
        )
        toast.success(`Box: (${data.name}) updated!`)
        emit('box:updated', {
          deepBoxNodeId: props.typeId,
          box: {
            ...data,
            boxName: data.name,
            displayName: data.name,
            boxNodeId: currentBoxData.value?.boxNodeId,
          },
        })
      } else {
        const res = await deepBoxAdminDeepBoxesBoxesAPI.post(props.typeId, data)
        toast.success(`Box: (${data.name}) created!`)
        emit('box:added', { deepBoxNodeId: props.typeId, box: res.data })
      }
      model.value = false
    } catch {
      toast.error(t('error.error_occurred'))
    } finally {
      isLoading.value = false
    }
  } else {
    toast.error(t('error.validation.invalid'))
  }
}

function onDevBtnClick() {
  formModel.value = currentBoxData.value
    ? {
        name: 'TestBox',
        boxVariant: '',
      }
    : {
        name: 'TestBox',
        boxVariant: '',
        addOwnersAsOrganizers: true,
      }
}
</script>
