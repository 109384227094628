<template>
  <v-navigation-drawer
    v-bind="$attrs"
    ref="drawerRef"
    v-model="model"
    class="prevent-select"
  >
    <v-fab
      v-if="mobile && model"
      id="drawer-state-switcher"
      absolute
      class="navigation-drawer-switch"
      data-test-id="navigation-drawer-switch"
      icon="fas fa-chevrons-left"
      location="top"
      rounded="lg"
      size="small"
      @click="model = false"
    ></v-fab>

    <slot></slot>

    <template #append>
      <slot name="append"></slot>
    </template>
  </v-navigation-drawer>
</template>

<script lang="ts" setup>
import { useDisplay } from 'vuetify'

const model = defineModel({ type: Boolean, default: false })

const { mobile } = useDisplay()
</script>
<style lang="scss" scoped>
.navigation-drawer-switch {
  position: absolute;
  right: -48px;
  top: 8px;
}
</style>
