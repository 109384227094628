<template>
  <v-layout>
    <!-- APP BAR -->
    <TheAppBar :hide-app-switch="hideAppSwitch" :is-mobile="device?.isMobile">
      <template #desktop-append>
        <template v-if="isAuthenticated">
          <AppBarFavoritesMenu
            v-if="
              deepAdminUsersMeStore.selectedOrganizationId &&
              deepBoxOverviewStore.overview
            "
            :favorite-boxes="deepBoxOverviewStore.overview.favorites.boxes"
            :organization-id="deepAdminUsersMeStore.selectedOrganizationId"
            :shared-boxes="deepBoxOverviewStore.overview.sharedWithMe.boxes"
          />
          <AppBarRecentMenu
            v-if="
              deepAdminUsersMeStore.selectedOrganizationId &&
              deepBoxOverviewStore.overview
            "
            :organization-id="deepAdminUsersMeStore.selectedOrganizationId"
            :recent-boxes="deepBoxOverviewStore.overview.recents.boxes"
            :shared-boxes="deepBoxOverviewStore.overview.sharedWithMe.boxes"
          />
          <AppBarServiceMenu
            v-if="deepAdminUsersMeStore.selectedOrganizationId"
            :current-organization="deepAdminUsersMeStore.selectedOrganization"
          />
        </template>
        <span></span>
      </template>
    </TheAppBar>
    <!-- /APP BAR -->

    <v-main class="bg-background-grey">
      <ContainerLayoutWrapper>
        <slot>
          <router-view />
        </slot>
      </ContainerLayoutWrapper>
    </v-main>
  </v-layout>
</template>

<script lang="ts" setup>
import AppBarFavoritesMenu from '@/components/appbar/AppBarFavoritesMenu.vue'
import AppBarRecentMenu from '@/components/appbar/AppBarRecentMenu.vue'
import AppBarServiceMenu from '@/components/appbar/AppBarServiceMenu.vue'
import TheAppBar from '@/components/appbar/TheAppBar.vue'
import ContainerLayoutWrapper from '@/components/containers/ContainerLayoutWrapper.vue'

import { useDeepAdminUsersMeStore } from '@/stores/deepadmin/users/users-me'
import { useDeepBoxOverviewStore } from '@/stores/deepbox/overview'
import { DeviceKey } from '@/plugins/device-detector-js.ts'
import { useKeycloak } from '@/composables/auth/use-keycloak.ts'

const device = inject(DeviceKey)

const deepBoxOverviewStore = useDeepBoxOverviewStore()
const deepAdminUsersMeStore = useDeepAdminUsersMeStore()

const { isAuthenticated } = useKeycloak()
const hideAppSwitch = computed(() => !isAuthenticated.value)
</script>
