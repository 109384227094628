import { type InjectionKey, type Plugin } from 'vue'
import Bowser from 'bowser'

export type Device = {
  isMobile: boolean
  isTablet: boolean
  isDesktop: boolean
  isAndroid: boolean
  isIos: boolean
  isMacOS: boolean
  isWindows: boolean
} & Bowser.Parser.ParsedResult

type DeviceKeys = keyof Device

const DEVICE_kEY = 'Device'
export const DeviceKey: InjectionKey<Device> = Symbol(DEVICE_kEY)

function devices(detector: Bowser.Parser.ParsedResult): Device {
  return {
    ...detector,
    isMobile: detector.platform.type === Bowser.PLATFORMS_MAP.mobile,
    isTablet: detector.platform.type === Bowser.PLATFORMS_MAP.tablet,
    isDesktop: detector.platform.type === Bowser.PLATFORMS_MAP.desktop,
    isAndroid: detector.os.name === Bowser.OS_MAP.Android,
    isIos: detector.os.name === Bowser.OS_MAP.iOS,
    isMacOS: detector.os.name === Bowser.OS_MAP.MacOS,
    isWindows: detector.os.name === Bowser.OS_MAP.Windows,
  }
}

// Create module definition for Vue.use()
const plugin: Plugin = {
  install(app, options = {}) {
    const flags: Device = reactive(
      devices(Bowser.parse(window.navigator.userAgent)),
    )
    app.config.globalProperties[`$${DEVICE_kEY}`] = flags
    app.provide(DeviceKey, flags)
    if (options?.refreshOnResize) {
      window.addEventListener('resize', () => {
        setTimeout(() => {
          const newFlags = devices(Bowser.parse(window.navigator.userAgent))
          Object.entries(newFlags).forEach((entry) => {
            const [key, value] = entry
            flags[key as DeviceKeys] = value
          })
        }, 50)
      })
    }
  },
}

// To allow use as module (npm/webpack/etc.) export component
export default plugin
