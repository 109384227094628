import { constants } from '@/constants'

export function useNodeSection() {
  const { t } = useI18n()

  function getNodeSectionTranslation(sectionName: string) {
    switch (sectionName) {
      case constants.SECTION_QUEUE:
      case constants.SECTION_INBOX:
        return t('box_details.navigation.inbox')
      case constants.SECTION_FILES:
        return t('box_details.navigation.files')
      case constants.SECTION_TRASH:
        return t('box_details.navigation.trash')
      default:
        return sectionName
    }
  }

  function getNodeSectionTranslationByRouteName(routeName: string) {
    if (routeName === 'organization-types-type-boxes-box-files') {
      return t('box_details.navigation.files')
    }
    if (routeName === 'organization-types-type-boxes-box-inbox') {
      return t('box_details.navigation.inbox')
    }
    if (routeName === 'organization-types-type-boxes-box-trash') {
      return t('box_details.navigation.trash')
    }
    if (routeName.includes('search')) {
      return t('page_titles.search_results')
    }
    return ''
  }

  function getNodeSectionIcon(sectionName: string) {
    switch (sectionName) {
      case constants.SECTION_QUEUE:
      case constants.SECTION_INBOX:
        return 'far fa-envelope'
      case constants.SECTION_FILES:
        return 'far fa-folder'
      case constants.SECTION_TRASH:
        return 'far fa-trash'
      default:
        return 'far fa-folder'
    }
  }

  return {
    getNodeSectionTranslation,
    getNodeSectionTranslationByRouteName,
    getNodeSectionIcon,
  }
}
