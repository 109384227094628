import type { Box, BoxEntry, BoxInfo } from '@/api/types/deepbox/box'

export function useBox(box: ComputedRef<Box | BoxInfo | BoxEntry | undefined>) {
  // boxTypes
  const isBoxTypePrivate = computed(() => box.value?.boxType === 'private')

  // boxVariants
  const isBoxVariantAdvanced = computed(
    () => box.value?.boxVariant === 'advanced',
  )

  const isBoxVariantAdvancedPerUse = computed(
    () => box.value?.boxVariant === 'advanced-per-use',
  )

  const isBoxVariantPrivate = computed(
    () => box.value?.boxVariant === 'private',
  )

  // box features
  const isBoxPrivate = computed(
    () => isBoxTypePrivate.value && isBoxVariantPrivate.value,
  )

  return {
    // boxTypes
    isBoxTypePrivate,
    // boxVariants
    isBoxVariantAdvanced,
    isBoxVariantAdvancedPerUse,
    isBoxVariantPrivate,
    // box features
    isBoxPrivate,
  }
}
