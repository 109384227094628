import { useDeepAdminUsersMeStore } from '@/stores/deepadmin/users/users-me'
import type { UserMeOrganization } from '@/api/types/deepadmin/users/user.ts'
import { useDeepBoxBoxInfoStore } from '@/stores/deepbox/box-info.ts'
import { useKeycloak } from '@/composables/auth/use-keycloak.ts'

export function useOrganization() {
  const deepAdminUsersMeStore = useDeepAdminUsersMeStore()
  const deepBoxBoxInfoStore = useDeepBoxBoxInfoStore()

  const { user } = useKeycloak()
  const lastOrgId = useLocalStorage(`last_org_${user.value?.sub}`, '')

  function setLastOrgIdIfValid(orgId: string) {
    if (!orgId) return
    const isValid =
      deepAdminUsersMeStore.getUserSelectableOrganizations.findIndex(
        (i) => i.group_id === orgId,
      )
    if (isValid !== -1) {
      lastOrgId.value = orgId
    }
  }

  function selectOrg(orgId: string, organizations?: UserMeOrganization[]) {
    // Check if the deepbox company id is part of user's organizations
    let org: UserMeOrganization | undefined
    if (organizations && organizations?.length > 0) {
      org = organizations.find((o) => o.group_id === orgId)
    } else {
      org = deepAdminUsersMeStore.organizations.find(
        (o) => o.group_id === orgId,
      )
    }

    if (org) {
      deepAdminUsersMeStore.selectedOrganization = {
        ...org,
      }
    } else {
      deepAdminUsersMeStore.selectedOrganization =
        deepAdminUsersMeStore.getUserSelectableOrganizations[0]
    }
    return deepAdminUsersMeStore.selectedOrganizationId
  }

  watch(
    () => deepAdminUsersMeStore.selectedOrganizationId,
    (newValue) => {
      if (newValue) {
        lastOrgId.value = newValue
      }
    },
  )

  const currentContextOrg = computed(
    () =>
      deepBoxBoxInfoStore.accessCompany ||
      deepAdminUsersMeStore.selectedOrganization,
  )

  const currentContextOrgId = computed(() => {
    if (!currentContextOrg.value) return undefined

    if ('companyId' in currentContextOrg.value) {
      return currentContextOrg.value.companyId
    }
    if ('group_id' in currentContextOrg.value) {
      return currentContextOrg.value.group_id
    }

    return undefined
  })

  return {
    selectOrg,
    setLastOrgIdIfValid,
    lastOrgId,
    currentContextOrg,
    currentContextOrgId,
  }
}
