<template>
  <!-- DESKTOP APPBAR -->
  <TheAppBar :is-mobile="smAndDown">
    <template #desktop-center>
      <div class="text-truncate">
        {{ t('store.app_bar.title') }}
      </div>
    </template>
    <!-- /DESKTOP APPBAR -->

    <!-- MOBILE APPBAR -->
    <template #mobile-content>
      <v-btn icon="fas fa-arrow-left" @click="goBack"></v-btn>
      <v-toolbar-title
        class="pl-0 d-flex flex-row align-center justify-center"
        style="width: 100%; font-size: 12px"
      />
      <AppBarUserAccountMenu />
    </template>
  </TheAppBar>
</template>

<script lang="ts" setup>
import TheAppBar from '@/components/appbar/TheAppBar.vue'
import AppBarUserAccountMenu from '@/components/appbar/AppBarUserAccountMenu.vue'
import { useDisplay } from 'vuetify'

const router = useRouter()
const { smAndDown } = useDisplay()
const { t } = useI18n()

const goBack = () => {
  router.back()
}
</script>

<style lang="scss" scoped>
.cls-container {
  max-width: 1040px;
  padding: 4px 32px !important;
}

.app-bar {
  :deep(.v-toolbar__content) {
    padding: 0 !important;
  }
}

.logo {
  width: 110px;
  height: 25px;
  background-image: url('/svg/logos/deepbox_logo.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

@media only screen and (max-width: 370px) {
  .logo {
    width: 92px;
  }
}

@media only screen and (max-width: 348px) {
  .logo {
    width: 24px;
    background-image: url('/svg/logos/deepbox_o.svg');
  }
}
</style>
