import type { Pagination } from '@/api/types/deepbox/pagination'
import type { Comment } from '@/api/types/deepbox/comment'
import { deepBoxNodesCommentsAPI } from '@/api/deepbox/nodes/nodes-comments'

export const useDeepBoxCommentsStore = defineStore('deepBoxComments', () => {
  const comments = ref<Comment[]>([])
  const pagination = ref<Pagination>({} as Pagination)
  const size = ref(0)
  const offset = ref(0)
  const limit = ref(50)
  const fetchNodeCommentsPending = ref(false)
  const fetchNodeCommentsMorePending = ref(false)
  const addNodeCommentPending = ref(false)
  const updateNodeCommentPending = ref(false)
  const deleteNodeCommentPending = ref(false)
  const fetchNodeCommentPending = ref(false)

  // NODE COMMENTS
  async function fetchNodeComments({
    nodeId,
    offset = 0,
    limit = 50,
    order = 'createdTime desc',
  }: {
    nodeId: string
    offset?: number
    limit?: number
    order?: string
  }) {
    fetchNodeCommentsPending.value = true
    try {
      const params = {
        offset,
        limit,
        order,
      }
      const res = await deepBoxNodesCommentsAPI.get(nodeId, params)
      const resData = res?.data
      if (resData.pagination.offset === 0) {
        comments.value = [...resData.comments]
      } else {
        comments.value = [...comments.value, ...resData.comments]
      }

      pagination.value = resData.pagination
      size.value = resData.size

      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    } finally {
      fetchNodeCommentsPending.value = false
    }
  }

  async function addNodeComment(payload: { nodeId: string; text: string }) {
    addNodeCommentPending.value = true
    try {
      const res = await deepBoxNodesCommentsAPI.post(
        payload.nodeId,
        payload.text,
        {
          headers: { 'Content-Type': 'text/plain' },
        },
      )
      const resData = res?.data
      comments.value = [resData].concat(comments.value)
      size.value += 1
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    } finally {
      addNodeCommentPending.value = false
    }
  }

  async function updateNodeComment(payload: {
    nodeId: string
    text: string
    commentId: string
  }) {
    updateNodeCommentPending.value = true
    try {
      const res = await deepBoxNodesCommentsAPI.patchById(
        payload.nodeId,
        payload.commentId,
        payload.text,
        {
          headers: { 'Content-Type': 'text/plain' },
        },
      )

      const resData = res.data
      const idx = comments.value.findIndex(
        (comment) => comment.commentId === resData.commentId,
      )
      if (idx !== -1) {
        Object.assign(comments.value[idx], payload)
      }
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    } finally {
      updateNodeCommentPending.value = false
    }
  }

  async function deleteNodeComment(payload: {
    nodeId: string
    commentId: string
  }) {
    deleteNodeCommentPending.value = true
    try {
      await deepBoxNodesCommentsAPI.deleteById(
        payload.nodeId,
        payload.commentId,
      )
      const idx = comments.value.findIndex(
        (comment) => comment.commentId === payload.commentId,
      )
      if (idx !== -1) {
        comments.value.splice(idx, 1)
        size.value -= 1
      }
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    } finally {
      deleteNodeCommentPending.value = false
    }
  }

  async function fetchNodeComment(payload: {
    nodeId: string
    commentId: string
  }) {
    fetchNodeCommentPending.value = true
    try {
      const res = await deepBoxNodesCommentsAPI.getById(
        payload.nodeId,
        payload.commentId,
      )
      const resData = res.data

      const idx = comments.value.findIndex(
        (comment) => comment.commentId === payload.commentId,
      )
      if (idx !== -1) {
        const nodeToUpdate = comments.value[idx]
        nodeToUpdate.hasMore = resData.hasMore
        nodeToUpdate.text = resData.text
        comments.value.splice(
          idx,
          1,
          Object.assign(comments.value[idx], nodeToUpdate),
        )
        Object.assign(
          comments.value[idx],
          Object.assign(comments.value[idx], nodeToUpdate),
        )
      }
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    } finally {
      fetchNodeCommentPending.value = false
    }
  }

  function updateNodeCommentProp(payload: {
    commentId: string
    prop: Record<string, unknown>
  }) {
    const idx = comments.value.findIndex(
      (comment) => comment.commentId === payload.commentId,
    )
    if (idx !== -1) {
      comments.value.splice(
        idx,
        1,
        Object.assign(comments.value[idx], payload.prop),
      )
      Object.assign(
        comments.value[idx],
        Object.assign(comments.value[idx], payload.prop),
      )
    }
  }

  function clearCommentsStore() {
    comments.value = []
    pagination.value = {} as Pagination
    size.value = 0
    offset.value = 0
    limit.value = 50
    fetchNodeCommentsPending.value = false
    fetchNodeCommentsMorePending.value = false
    addNodeCommentPending.value = false
    updateNodeCommentPending.value = false
    deleteNodeCommentPending.value = false
    fetchNodeCommentPending.value = false
  }

  function clearNodeComments() {
    comments.value = []
  }

  function updateApiParams(payload: { offset: number; limit: number }) {
    offset.value = payload.offset
    limit.value = payload.limit
  }

  function resetApiParams() {
    offset.value = 0
    limit.value = 50
  }

  return {
    // state
    comments,
    pagination,
    size,
    offset,
    limit,
    fetchNodeCommentsPending,
    fetchNodeCommentsMorePending,
    addNodeCommentPending,
    updateNodeCommentPending,
    deleteNodeCommentPending,
    fetchNodeCommentPending,
    // actions
    fetchNodeComments,
    addNodeComment,
    updateNodeComment,
    deleteNodeComment,
    fetchNodeComment,
    updateNodeCommentProp,
    clearCommentsStore,
    clearNodeComments,
    updateApiParams,
    resetApiParams,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useDeepBoxCommentsStore, import.meta.hot),
  )
}
