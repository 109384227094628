// styles
import './styles/main.scss'
import '@deepcloud/deep-ui-lib/dist/style.css'

// Components
import App from './App.vue'

// Composables
import { useKeycloak } from './composables/auth/use-keycloak'

// Plugins
import { registerPlugins } from './plugins'

const keycloak = useKeycloak()

keycloak.init(onAuthFinish)

async function onAuthFinish(authenticated) {
  const app = createApp(App)

  registerPlugins(app)

  // With this "hack" we force the KC token to be always update on window focus
  // this is needed for the logout from another domain like: deepsign.swiss or deepcloud.swiss
  window.addEventListener(
    'focus',
    async function () {
      if (authenticated) {
        keycloak.loadUserInfo().catch(() => {
          keycloak.updateToken(-1)
        })
      }
    },
    false,
  )

  app.mount('#app')
}
