<template>
  <DeepDialog
    :closable="false"
    max-width="700"
    :model-value="show"
    :title="t('overlays.overused_subscription.title')"
  >
    <template #content>
      <v-card-item class="pt-0 pb-4 px-1">
        <MenuOrganizationSelection
          v-if="deepAdminUsersMeStore.selectedOrganization"
        />
      </v-card-item>
      <v-alert
        class="ma-0"
        density="compact"
        icon="far fa-exclamation-triangle"
        type="warning"
        variant="tonal"
      >
        <template #title>
          <span class="text-subtitle-1">
            {{ t('overlays.overused_subscription.subtitle') }}
          </span>
        </template>
        <template #text>
          <span class="text-body-2">
            {{ getText }}
          </span>
        </template>
      </v-alert>
      <v-card-text class="text-caption px-0 pb-0">
        {{ t('overlays.overused_subscription.learn_more') }}

        <DeepLink :href="linkKnowledgeCenterSubscriptions"
          >support.deepcloud.swiss
        </DeepLink>
      </v-card-text>
    </template>

    <template #actions>
      <VBtnTertiary v-if="!isProdEnv()" color="error" @click="ignore">
        {{ t('buttons.ignore_dev_int_only') }}
      </VBtnTertiary>
      <template v-if="isOwner">
        <v-spacer />
        <VBtnSecondary @click="manageOrganization">
          {{ t('overlays.overused_subscription.manage_organization_btn') }}
        </VBtnSecondary>
        <VBtnPrimary @click="manageSubscription">
          {{ t('overlays.overused_subscription.manage_subscription_btn') }}
        </VBtnPrimary>
      </template>
    </template>
  </DeepDialog>
</template>
<script lang="ts" setup>
import helpers from '@/utils/helpers.ts'
import { useDeepAdminUsersMeStore } from '@/stores/deepadmin/users/users-me'
import { useDeepAdminUsersCompanyAccountingStore } from '@/stores/deepadmin/users/users-company-accounting'
import { useKeycloak } from '@/composables/auth/use-keycloak'
import { isProdEnv } from '@/utils/helpers/env.ts'
import { DeepDialog, DeepLink } from '@deepcloud/deep-ui-lib'
import { useKnowledgeCenter } from '@/composables/use-knowledge-center.ts'
import MenuOrganizationSelection from '@/components/menu/MenuOrganizationSelection.vue'

const { t } = useI18n()
const { user } = useKeycloak()

const deepAdminUsersMeStore = useDeepAdminUsersMeStore()
const deepAdminUsersCompanyAccountingStore =
  useDeepAdminUsersCompanyAccountingStore()

const ignored = useLocalStorage(
  `ignore_overused_deepbox_overlay_${user.value?.sub}`,
  {},
)

const isOrgIgnored = computed(() => {
  if (!deepAdminUsersMeStore.selectedOrganizationId) return false
  return Object.keys(ignored.value).includes(
    deepAdminUsersMeStore.selectedOrganizationId,
  )
})

const show = computed(() => {
  if (!isProdEnv()) {
    return (
      deepAdminUsersCompanyAccountingStore.companyAccounting
        .has_overused_box_users && !isOrgIgnored.value
    )
  }
  return (
    deepAdminUsersCompanyAccountingStore.companyAccounting
      .has_overused_box_users || false
  )
})

const currentOrganization = computed(() => {
  return helpers.currentOrganization(
    deepAdminUsersMeStore.selectedOrganization,
    deepAdminUsersMeStore.organizations,
  )
})
const isOwner = computed(() =>
  helpers.isOwnedOrganization(currentOrganization.value),
)

const getText = computed(() => {
  return isOwner.value
    ? t('overlays.overused_subscription.text_owner')
    : t('overlays.overused_subscription.text_member')
})

const { linkSubscriptions: linkKnowledgeCenterSubscriptions } =
  useKnowledgeCenter()

const ignore = () => {
  ignored.value = {
    ...ignored.value,
    [`${deepAdminUsersMeStore.selectedOrganizationId}`]: true,
  }
}

const manageSubscription = () => {
  window.open(
    `${import.meta.env.VITE_DEEPADMIN_FRONTEND_BASE_URL}organizations/${
      deepAdminUsersMeStore.selectedOrganizationId
    }/subscription`,
    '_self',
  )
}

const manageOrganization = () => {
  window.open(
    `${import.meta.env.VITE_DEEPADMIN_FRONTEND_BASE_URL}organizations/${
      deepAdminUsersMeStore.selectedOrganizationId
    }`,
    '_self',
  )
}
</script>
<style lang="scss" scoped>
.card-title {
  word-break: normal !important;
}
</style>
